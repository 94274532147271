.actions-popover-container {
  display: flex;
  flex-direction: column;
  padding: 12px 24px 12px 12px;
  row-gap: 10px;
  border-radius: 5px;

  .drp-action-item {
    font-size: 12px !important;
    letter-spacing: 0;
  }

  .dropdown-action-link-item {
    text-decoration: none;
    letter-spacing: 0px;
    color: #131F6B;
    padding: 1px;

    &.Heatmap{
      color: #131F6B;
    }
  }
  .dropdown-action-btn-item {
    padding: 0 !important;
    width: fit-content;
    &.Remove {
      color: #E88989;
    }
    &:not(.Remove) {
      color: #131F6B;
    }
  }
}