.user_select_inp_val {
  left: 10px; 
  top: 8px;
}

input[id^='react-select-'] {
  opacity: 1 !important;
}

.light-gray {
  color: #d2d4d9;
}