.plan-progress {
  width: 100%;
  display: block;
  height: 10px;
  border-radius: 2px;
  background: #fff;
  overflow: hidden;

  .progress-completed {
    height: 100%;
    display: block;
    background: #131f6b;
    border-radius: 2px;
  }

  &.progress-incomplete .progress-completed {
    background: $color-tomato;
  }
}

.course-accordion {
  .text-date {
    font-size: 10px;
    color: #a0a0a0;
  }

  .item-content {
    padding: 0 20px 20px;

    .accordion-sub-item {
      li:nth-last-child(1) {
        margin-bottom: 0 !important;
      }
    }

    .course-progress-block {
      padding: 20px;
      border: 1px solid #E2E2E2;
      border-radius: 5px;
    }

    .course-lessons-block {
      display: none;
      padding-top: 30px;
      &.active {
        display: block;
      }
    }
  }

  .pass-button {
    width: 130px;
    &:disabled {
      border-color: #A7A7A7;
      background: #A7A7A7;
      color: #fff;
      &:hover {
        background: #A7A7A7 !important;
      }
    }
  }

}
