.grey_datepicker {
  height: 31px;
  width: 100% !important;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #F4F7FC 0% 0% no-repeat padding-box;
  border-radius: 5px;
  opacity: 1;
  margin-right: 0 !important;

  &.white-bg {
    background: #fff;
  }

  &:not(.is-opened) {
    overflow-y: hidden;
  }
  
  .calendar-header {
    width: 100%;
    font-weight: 400;

    color: #242836 !important;
    &.no-value {
      opacity: 0.5;
    }
    &.range_selected {
        opacity: 1;
    }
    &.not_allowed {
      opacity: 0.5 !important;
    }
    letter-spacing: 0.28px;
  }
}