.right-details-wrapper {
  .title {
    font-size: 18px;
    font-weight: $primary-color;
  }
  .entity-banner-block {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }
  .empty-img-block {
    width: 100%;
    height: 100px;
    border-radius: 5px;
    background-color: #fff;
    font-size: 36px;
    font-weight: 400;
    color: #000;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .line-info {
    font-size: 12px;
    font-weight: 400;
    color: #222222;
    max-width: 100%;
  }
  .outlined-action-btn {
    border: 2px solid $primary-color;
    border-radius: $radius-btn-main;
  }
}