// Colors

$color-blue: #131f6b;
$color-blue-light: #eff4fb;
$color-blue-semi-light: #ccdef7;
$color-tomato: #d95555;

$gray-50: #F9FAFB;
$gray-300: #D0D5DD;
$gray-500: #667085;
$gray-700: #344054;
$dicom-viewer-color: #B1B1B1;
$bg-disabled-main: #B1B1B1;
$purple-main: #AF3DB2;
$bg-gray-main: #F1F3F5;
$bg-gray-dark: #F0F3F5;
$bg-primary: #EFF4FB;
$text-main-color: #2B2967;
$text-regular-color: #222222;
$primary-color: #131F6B;
$black-main: #222222;
$gaid-shape-active: #EDD500;
$gaid-text-primary-color: #A4A4A4;

// Border radiuses

$radius-input-main: 8px;
$radius-btn-main: 5px;

$font-size-main: 14px;
$line-height-main: 18px;

$font-family-base: 'Roboto', sans-serif!default;

$theme-colors: map-merge(
  (
    "primary":                #131f6b,
  ),
  $theme-colors
);

// FONTS

$font_primary_light: normal normal 300 14px/20px Roboto;