.loading {
  position: fixed;
  z-index: 100;
  width: 100%;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  background: rgba(255, 255, 255, 0.8);

  .spinner-border {
    width: 3rem;
    height: 3rem;
  }
}
