.header-section {
  font-weight: 100;
  min-height: 80px;
  -webkit-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.16);
  -moz-box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 0px 0px 7px 0px rgba(0, 0, 0, 0.16);
  position: relative;
  &.has-pad {
    padding-right: 65px !important;
  }
}

.course-view {
  height: 100vh;

  .header-section {
    padding-right: 70px !important;
  }

  .course-name {
    color: #131F6B;
    font-size: 17px;
    font-weight: 500;
    border-right: 2px solid #e4e4e4;
  }

  .course-authors {
    span {
      font-size: 17px;
      color: #131F6B;
      font-weight: 300;
    }
    a {
      color: #4b569c;
    }

    .app-dropdown-header {
      &:before, &:after {
        margin-top: 2px;
      }
    }
  }

  .body-section {
    display: flex;
    padding-top: 3px;
    min-height: 470px;
    border-bottom: 2px solid #eff4fb;

    &.no_border {
      border: none;
    }

    .video-area {
      width: 76%;
      height: 100%;
      min-width: 700px;
      flex: 1;
    }

    .empty_chapters_body {
      display: flex;
      flex-direction: column;
      row-gap: 12px;

      .description {
        letter-spacing: 0px;
        font-size: 16px;
        max-width: 190px;
      }
    }

    .empty_chapters_section {
      width: 24%;
      height: 100%;
      padding: 18px;
      background-color: #fff;

      .header {
        border-bottom: 2px solid #eff4fb;

        .title {
          font-size: 14px;
          font-weight: bold;
          letter-spacing: 0.28px;
          color: #2B2967;
        }
      }

      .body {
        padding: 20px 0;
        display: flex;
        flex-direction: column;
        row-gap: 12px;

        .description {
          letter-spacing: 0px;
          font-size: 16px;
          max-width: 190px;
        }
      }
    }
  }

  .video-area {

    .video-react-video {
      background: #EFF4FB;
    }

    video {
      outline: none;
    }

    .video-react-big-play-button.video-react-big-play-button-center {
      width: 83px !important;
      height: 83px !important;
      border-radius: 100% !important;
      border-width: 1px !important;
      background: rgba(46, 48, 94, .65) !important;
      &:before {
        font-size: 50px;
        line-height: 80px;
      }
    }
    .video-react-playing {
      .video-react-big-play-button.video-react-big-play-button-center {
        display: none;
      }
    }
  }

  .video-area {
    background-color: #EFF4FB;
  }

  .course-parts {
    background: #fff;
    padding: 10px 25px 10px 10px;
    width: 310px;

    .course-title {
      padding: 5px 5px 2px;
      color: #2B2967;
      font-size: 14px;
      margin: 0 0 8px 0;
      border-bottom: 2px solid #EFF4FB;
    }
  }

  .course-parts-lists {
    list-style-type: none;
    padding: 0;
    padding-top: 8px;
    height: 100%;
    overflow-y: auto;
    div.part-item {
      padding: 2px 0;
      border-bottom: 2px solid #eff4fb;
      min-height: 55px;
      cursor: pointer;
      &.active {
        background: #eff4fb;
        .time, .name {
          opacity: 1;
        }
      }
      &.disabled {
        cursor: not-allowed;
      }
      svg {
        width: 9px;
        height: 12px;
        path {
          fill: #131f6b;
        }
      }
      p {
        margin: 0;
      }
      &:not(.disabled) p {
        color: #242836;
      }
      &.disabled {
        p {
          color: #a7a7a7;
        }
        svg {
          path {
            fill: #a7a7a7;
          }
        }
      }
      .name {
        opacity: 0.8;
        font-size: 12px;
        font-weight: bold;
      }
      .time {
        font-size: 11px;
        opacity: 0.3;
      }
      &:nth-last-child(1) {
        border-bottom: none;
      }
    }
  }

  .footer-section {
    height: 100%;
    background: #fff;
    z-index: 100;
  }

  .info-area {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    font-weight: 200;
    max-width: 500px;
    h3 {
      font-weight: 400;
      font-size: 25px;
      color: #000000;
      margin-bottom: 0;
    }
    p {
      font-size: 16px;
      font-weight: 300;
      color: #000000;
    }
    .exp_date_block {
      height: 60px;
      width: 343px;
      display: flex;
      column-gap: 12px;
      justify-content: center;
      align-items: center;
      background: #EFF4FB 0% 0% no-repeat padding-box;

      .description {
        font-size: 13px;
        color: #242836;
        margin-bottom: unset;
      }
    }

    .tags_block {
      height: 77px;
      width: 343px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      column-gap: 12px;
      padding: 12px;
      background: #EFF4FB 0% 0% no-repeat padding-box;

      .description {
        font-size: 13px;
        color: #242836;
        margin-bottom: unset;
      }
    }
  }

  .courses-area {
    height: 100%;
    overflow-y: auto;
    min-height: 220px;
    padding-right: 10px !important;

    .feedback_section {
      padding: 12px 42px;
      display: flex;
      align-items: center;
      column-gap: 9px;

      .course_icon_block {
        height: 66px;
        width: 118px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #2B2967 0% 0% no-repeat padding-box;
      }

      .course_desc_block {
        display: flex;
        flex-direction: column;
        justify-content: center;
        row-gap: 3px;

        * {
          margin-bottom: unset;
        }

        .title {
          font-size: 17px;
          font-weight: bold;
          letter-spacing: 0.34px;
          color: #242836;
        }

        .description {
          font-size: 13px;
          letter-spacing: 0.26px;
          color: #242836;
        }
      }
    }
    .list-item {
      padding: 8px 11px;
      // height: 82px;
      border-bottom: 2px solid #eff4fb;
      p {
        color: #242836;
        font-size: 13px;
        margin: 0;
      }
      p.name {
        font-size: 17px;
        font-weight: bold;
      }
      p.description {
        // height: 40px;
        // width: 80%;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      img {
        width: 118px;
        // height: 64px;
      }
      svg {
        width: 13px;
        height: 17px;
        path {
          fill: #131f6b;
        }
      }
      &.active {
        position: relative;
        cursor: pointer;
        background: #eff4fb;
        border-right: 1px solid #949cc0;
        &:after {
          content: '';
          display: block;
          position: absolute;
          top: calc(50% - 8px);
          right: -8px;
          width: 15px;
          height: 15px;
          background: #eff4fb;
          border-right:1px solid #949cc0;
          border-bottom:1px solid #949cc0;
          transform:rotate(-45deg);
          -moz-transform:rotate(-45deg);
          -webkit-transform:rotate(-45deg);
        }
      }
      &.disable {
        cursor: not-allowed;
        opacity: 0.5;
      }
      .time-part {
        color:#242836;
        font-size: 11px;
        font-weight: bold;
        svg {
          width: 13px;
          height: 10px;
        }
      }
    }
  }
}

.seek-Layout {
  position: absolute;
  height: 10px;
  top: 50%;
  right: 0;
  transform: translateY(-50%);
  transform-origin: center right;
  z-index: 1;
  cursor: not-allowed;
  background: rgba(255, 0, 0, 0.438);
}

.react-pdf__Document {
  height: 100%;
  overflow: auto;
}

.case-part {
  width: 100%;
  position: relative;
  height: 100%;
  overflow: hidden;
  background: #000;
  img {
    // opacity: 0.8;
    max-height: 465px;
  }
  .play-btn {
    position: absolute;
    width: 83px;
    height: 83px;
    border-radius: 100%;
    border-width: 1px;
    background: rgba(19, 31, 107, 0.72);
    z-index: 1;
    text-align: center;
    line-height: 83px;
    top: 50%;
    left: 50%;
    margin-top: -41.5px;
    margin-left: -41.5px;
    border: 1px solid #fff;
    svg {
      width: 27px;
      height: 28px;
      margin-left: 4px;
      path {
        fill: #fff;
      }
    }
  }
}

.footer-nav {
  background-color: #131f6b;
  color: #b5bef6;
  position: fixed;
  bottom: 0;
  right: 0;
  padding: 5px;
  border-top-left-radius: 8px;
  z-index: 1000;
  font-size: 10px;
  a {
    text-decoration: none;
    color: #b5bef6;
  }
  svg {
    width: 13px;
  }
}


.course-info-progress {
  padding: 15px 10px;
  background: #EFF4FB;
  p {
    margin: 0;
    margin-top: 3px;
    font-size: 14px !important;
    color: #242836 !important;
  }
}

.tags-view-block, .share-course-block {
  margin: 10px 0;
  border-radius: 3px;
  background: #EFF4FB;
  padding: 20px 16px 15px;

  h1 {
    text-align: left;
    letter-spacing: 0.34px;
    color: #242836;
    font-size: 17px;
    margin-bottom: 14px;
    font-weight: 600;
  }

  .tag-wrap {
    margin-left: -5px;
    margin-right: -5px;
  }

  .tag-item {
    font-size: 13px;
    color: #131F6B;
    font-weight: 400;
    background: #FFFFFF;
    border-radius: 5px;
    padding: 6px 9px;
    margin: 5px;
  }

  &.course-view-tags {
    padding: 16px 20px;
    h1 {
      font-size: 16px;
      margin-bottom: 11px;
    }
  }

  .course-not-shared-block {
    height: 100%;
    width: 100%;
  }

  .public-shared-text-container {
    display: flex;
    flex-direction: column;
    margin: 60px 0;

    p {
      margin-bottom: 0;

      text-align: center;
      font-size: 13px;
      letter-spacing: 0px;

      color: #242836;
      opacity: 0.5;
    }
  }
}

.not-shared-modal-content {
  padding: 25px 0 16px 0;
  font-size: 13px;
}

.share-course-block {
  min-height: 280px;
  &.is-small-box {
    min-height: 100px;
  }
}

.lesson-image-area {
  min-width: 120px;
  min-height: 74px;
}

.default-form {

  .rating {
    font-size: 28px;
    font-weight: 400;

    span {
      margin-right: 5px;
    }
  }

  font-size: 14px;
  li {
    min-height: 49px;
    background: $color-blue-light;
    border-radius: 5px;
    color: #222222;
    font-weight: bold;
    margin-bottom: 7px;
    padding: 9px 9px 9px 21px;

    &:focus-within {
      background: $color-blue-semi-light;
    }
  }

  span.result {
    font-weight: 300;
    padding: 0 13px;
  }

  .input-group {
    border: none;
    margin: 0 !important;
  }

  .input-group input, select, textarea {
    padding: 0 13px;
    height: 31px;
    border-radius: 5px;
    background: #fff;
    font-size: 14px;
    border: none;

    &::-webkit-input-placeholder {
      color: #ddd
    }
  }

  textarea {
    resize: none;
    outline: none;
    width: 100%;
    height: 150px;
    padding-left: 13px !important;
    padding-top: 7px !important;
  }

  .input-with-button {
    position: relative;

    input {
      padding-right: 80px;
    }

    button {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translateY(-50%);
    }
  }
}

.btn-contact-send {
  font-size: 12px;
  padding: 8px 27px;
  color: #131f6b;
}
