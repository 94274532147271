.form-frames-block {
  width: 100%;
  display: flex;
  gap: 8px;
  overflow-x: auto;
  padding: 2px;

  .frame-item {
    position: relative;
    width: 150px;
    height: 150px;
    border-radius: 5px;
    background-color: #fff;
    flex-shrink: 0;
    padding: 8px;
    cursor: pointer;
    box-sizing: border-box;
    display: flex;
    justify-content: center;
    align-items: center;
    
    &:hover, &.active {
      outline: 2px solid #131F6B;
    }

    .img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .no-frame-text {
      position: absolute;
      top: 67px;
      left: 45px;
      color: $primary-color;
      font-size: 14px;
      font-weight: 700;
    }
  }
}