.email_verify_container {
  background-color: #fff;
  padding: 0 300px;
  @media screen and (max-width: 1000px) {
    padding: 0 100px;
  }

  > div {
    max-width: 350px;
  }

  .title {
    text-align: center;
    letter-spacing: 0.4px;
    color: #242836;
    font-weight: bold;
    font-size: 20px;
  }
  .sub_title {
    text-align: center;
    letter-spacing: 0.34px;
    color: #242836;
    font-size: 17px;
    font-weight: lighter;
  }
  .resend_btn {
    color: #242836;
    font-size: 16px;
    letter-spacing: 0.32px;

  }
}

.verify_code_block {
  gap: 20px;
  .verify_code_sub_block {
    gap: 8px;
  }
}