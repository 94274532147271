.alert-message {
  position: fixed;
  top: 20px;
  right: 20px;
  background-color: #333;
  color: #fff;
  padding: 10px 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  z-index: 1000;
  opacity: 0;
  transform: translateX(100%);
  transition: transform 0.5s ease-out, opacity 0.5s ease-out;
}

.alert-message.animate-in {
  transform: translateX(0);
  opacity: 1;
}

.alert-message.animate-out {
  transform: translateX(100%);
  opacity: 0;
}

.alert-message.success {
  background-color: #4CAF50;
}

.alert-message.error {
  background-color: #f44336;
}

.alert-message.warning {
  background-color: #ff9800;
}
