.bar-chart-area {
  .names {
    font-size: 12px;
    color: #131f6b;
    font-weight: bold;
  }

  .color-box {
    width: 8px;
    height: 8px;
    border-radius: 10px;
    display: inline-block;
    margin-right: 10px;
  }

  .bar-chart {
    position: relative;
    > div {
      margin-right: 13px;
      &:nth-last-child(1) {
        margin-right: 0;
      }
    }
    .bar {
      position: relative;
      width: 21px;
      background: #ecedee;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px;
      overflow: hidden;
      min-height: 120px;
      .pulp {
        position: absolute;
        width: 100%;
        bottom: 0;
      }
    }
    &:after {
      content: '';
      position: absolute;
      bottom: 21px;
      left: -2%;
      height: 2px;
      background: #131f6b;
      width: 104%;
    }
  }

  .percent {
    margin-top: 7px;
    font-size: 11px;
    color: #131f6b;
    font-weight: bold;
  }

  &.is-vertical {
    flex-direction: column;
    .bar-chart {
      min-height: 125px;
    }
    .names {
      margin-top: 20px;
      padding: 0;
    }
  }
}
