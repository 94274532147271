.dnd_block_container {
  height: 630px;
  border: 2px dashed #AFAFAF;
  border-radius: 4px;
  margin-top: 16px;
  padding: 20px 0 20px 20px;
  .title {
    color: #000;
  }
  .steps_part_block {
    .gradient {
      &.start {
        left: -1px;
      }
    }
  }
  .sortable_img_item {
    position: relative;
    border-radius: 6px;
    flex-shrink: 0;
    border: 1px solid #EBEBEB;
    &.medium {
      width: 134px;
      height: 92px;
    }
    &.small {
      width: 76px;
      height: 56px;
    }
    &.active {
      border: 2px dashed #AFAFAF;
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
    .item_remove_icon {
      position: absolute;
      right: 0;
    }
  }
  .selected_item_desc_block {
    display: flex;
    align-items: center;
    gap: 8px;
    height: 40px;
  }
}

.columns_container {
  display: flex;
  column-gap: 8px;
  width: 100%;
  max-width: 100%;
  align-items: center;
  overflow-y: hidden;
  overflow-x: auto;
  padding: 10px 0px;
}

.category_column {
  flex: none;
  width: 190px;
  height: 280px;
  border-radius: 6px;
  overflow-y: hidden;
  border: 1px solid #EBEBEB;
  &:hover {
    .not_visible {
      visibility: visible !important;
    }
  }
  &.overed {
    border: 2px dashed #AFAFAF
  }

  .header {
    width: 100%;
    height: 35px;
    background-color: #EFF3FC;
    display: flex;
    padding: 4px 4px 4px 16px;
    align-items: center;
    gap: 8px;
    
    .title {
      margin-bottom: unset;
      color: #131F6B;
      width: 58%;
    }
  }
  .body {
    background-color: #FAFAFA;
    height: calc(100% - 35px);
    width: 100%;
    padding: 15px 12px;
    .column_body_items_block {
       display: flex; 
       column-gap: 7px;
       row-gap: 5px; 
       flex-wrap: wrap;
       max-height: 100%;
       overflow: auto;
    }
  }
}

.empty_col_img_space {
  display: inline-block;
  width: 75px;
  height: 55px;
  border: 2px dashed #AFAFAF;
  border-radius: 6px;
}

.img_desc {
  color: #181818;
}

.disabled_img_desc {
  color: #AFAFAF;
}