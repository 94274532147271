.progress-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 10px;
  .percent-txt {
    font-weight: 800;
    font-size: 16px;
  }
}

.landing-circle-progress {
  margin: 8px;
  position: relative;
  .white-item-block {
    position: absolute;
    z-index: 1;
    .progress-tick {
      position: absolute;
      z-index: 1;
    }
  }
  .fill-progress-item {
    position: absolute;
    z-index: 2;
    left: 0;
  }
}