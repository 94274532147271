.error-text {
  color: #ff6060;
  font-size: 12px;
}

.select-box {
  position: relative;
  background: #fff;
  border-radius: 5px;
  &::before {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    background: #fff;
    position: absolute;
    top: 6px;
    right: 10px;
    z-index: 2;
    transform: rotate(45deg);
  }
  &::after {
    content: '';
    display: block;
    width: 10px;
    height: 10px;
    background: $color-blue;
    position: absolute;
    top: 8px;
    right: 10px;
    z-index: 1;
    transform: rotate(45deg);
  }
  select.custom-select  {
    background: transparent !important;
    z-index: 99;
    position: relative;

    &.empty_label_lightgray {
      color: #91939A;
    }
  }
}
