.auth-pages {
  display: flex;
  height: 100vh;
  background-image: url('./../../assets/background.png');
  background-repeat: no-repeat;
  justify-content: center;
  background-position-y: bottom;
  background-size: 100% auto;

  .auth-block {
    width: 480px;
    height: 100%;
    background: #fff;
    display: flex;
    align-items: center;
    flex-direction: column;

    &.large {
      width: 984px;
    }
  }

  h3.title {
    font-size: 20px;
    color: #242836;
    font-weight: 500;
  }

  .auth-thanks-block {
    margin-top: 100px;
  }

  .auth-thanks-block {
    .title {
      border-bottom: 2px solid #d3d4d7;
      font-size: 37px;
      font-weight: bold;
      padding-bottom: 26px;
    }
  }

  .auth-success-block {
    margin-top: 50px;
    border-bottom: 2px solid #d3d4d7;
    padding-bottom: 5px;

    .title {
      font-size: 20px;
    }
    .sub-title {
      font-size: 17px;
    }
  }

  .reg-block {
    width: 625px;
    height: 561px;
    box-shadow: 0px 4px 59.8px 5.2px rgba(0, 12, 85, 0.14);
    color: #242836;
    .head-part p {
      font-size: 18px;
      font-weight: bold;
    }
    .head-part, .bottom-part {
      background: #fff;
      min-height: 61px;
    }
    .content-part {
      background: #eff4fb;
      font-size: 14px;
      overflow-y: auto;
      p.stronger {
        font-size: 15px;
        font-weight: bold;
      }
    }
    .bottom-part {
      opacity: 0.5;
    }
  }
}
