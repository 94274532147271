.specialty-images-block {
  display: flex;
  flex-wrap: wrap;
}

.form-inputs-block {
  row-gap: 8px;
  .add-link-btn {
    width: 143px;
    align-self: flex-end;
  }

  .remove-btn {
    width: 143px;
    align-self: flex-end;
  }
}

.product-courses-container {
  margin-right: 33px;
  padding-right: 16px;
  max-height: calc(100vh - 230px);
}

.switch-block {
  display: flex;
  gap: 18px;
  color: #60636C;
  font-size: 12px;
  font-weight: 500;
  .bold {
    color: #242836;
  }
}

.product-page-dropdown-header {
  padding: 4px 24px 4px 12px !important;
  width: 80px;
  border-radius: 5px;
  background-color: #ffffff;
  margin-right: 8px;
  &::before {

  }
}

.product-dropdown-body {
  width: unset;
  min-width: unset !important;
  margin-top: 0 !important;
  box-shadow: none !important;
  padding: 0 !important;
  .product-title-dropdown {
      .product-title-item {
        background-color: transparent;
        padding: 0 16px;
        width: 80px;
        height: 40px;
        margin-bottom: 0;
        display: flex;
        align-items: center;
        border-radius: 0;
        cursor: pointer;
        &:hover {
          background-color: #131F6B;
          svg {
            path {
              stroke: #fff;
            }
          }
        }
    }
  }
}

.select-entity-block {
  padding: 9px !important;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
  font-weight: 400 !important;
  overflow: hidden;

  .form-row {
    border-radius: 5px;
    background-color: #fff;
    padding: 4px 12px;
  }
  .entities-block {
    display: flex;
    column-gap: 11px;
    svg {
      height: 16px;
    }
  }
  .icon {
    height: fit-content;
  }
  .light-bordered-select {
    border: 1px solid #EFF4FB
  }
}

.page-card {
  width: 332px;
  min-height: 394px;
  border-radius: 10px;
  box-shadow: 0px 9px 11.5px 0px #00000029;
  overflow: auto;
  display: flex;
  flex-direction: column;
  .banner-sec {
    position: relative;
    width: 100%;
    height: 189px;
    .banner-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .actions-block {
      position: absolute;
      top: 8px;
      right: 8px;
    }
  }
  .details-sec {
    position: relative;
    padding: 24px 24px 16px;
    display: flex;
    flex-direction: column;
    row-gap: 8px;
    flex: 1;
    justify-content: space-between;
    .title-row {
      display: flex;
      column-gap: 4px;
      .title {
        font-size: 20px;
        font-weight: 700;
        color: #222222;
      }
    }
    .status {
      position: absolute;
      top: 14px;
      right: 8px;
      font-size: 12px;
      font-weight: 700;
      &.Unpublished {
        color: #E88989;
      }
      &.Published {
        color: #63E566;
      }
    }
    .desc {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
  .properties-row {
    display: flex;
    color: #222222;
    column-gap: 16px;
    justify-content: space-between;
    .item:not(:last-child)::after {
      content: '';
      position: absolute;
      right: 0;
      top: 50%;
      transform: translateY(-50%);
      height: 50%;
      border: 1px solid #B6B6B6;
      margin-right: -14px;
    }
    .item {
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      p {
        margin-bottom: 0;
      }
      .count {
        font-size: 24px;
        font-weight: 700;
        line-height: 32px;
      }
      .name {
        font-size: 12px;
        font-weight: 400;
        line-height: 12px;
      }
    }
  }
}

.external-link-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px
}