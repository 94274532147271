.table-accordion-container {
  &.summary-type-white {
    .list-item-white {
      background: transparent;
      box-shadow: 0px 0px 8px 0px #00000029;
    }
  }
  .user-cell-txt-block {
    display: flex;
    flex-direction: column;
    row-gap: 4px;
  }
  .row-user-image {
    width: 40px;
    height: 40px;
    border-radius: 50% !important;
    object-fit: cover;
  }
  .cell-with-image {
    padding-left: 15px;
  }
  .list-item {
      margin-bottom: 0 !important;
  }
  .list-header {
    height: auto !important;
    font-weight: 400;
  }
  .inner-list-header {
    padding-left: 22px;
  }
  .bordered-list-header {
    border-bottom: 1px solid #B6B6B6;
  }
  .bordered-list-row {
    &:not(:last-child) {
      border-bottom: 1px solid #B6B6B6;
    }
  }
  .cell-text-regular {
    font-size: 12px;
    font-weight: 400;
    color: $black-main;
  }
  .cell-text-blue {
    font-size: 14px;
    font-weight: 400;
    color: $primary-color;
  }
}