.survey-web-container {
  padding: 31px 100px;

  .head-section {
    height: 100%;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    column-gap: 20px;
    justify-content: space-between;

    .title-part {
      height: 100%;
      width: 60%;
      min-width: 400px;

      h1 {
        font-size: 24px;
      }

      p {
        font-size: 14px;
      }
    }

    .actions-part {
      height: 100%;
      display: flex;
      column-gap: 15px;

      button {
        height: 55px;
        width: 185px;
        font-size: 18px;

        &.btn-blue-outlined {
          border: 2px solid #131F6B;
        }
      }
    }
  }
}

.question-cards-container {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 40px;
}

.survey-accordion-content {
  width: 100%;
  display: flex;
  flex-direction: column;

  .head-sec {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 20px 0;

    .btn-outline-blue {
      width: 152px;
      height: 44px;

      font-size: 18px;

      border: 2px solid #131F6B;
    }

    .right-actions-container {
      display: flex;
      align-items: center;
      column-gap: 25px;
    }

    .access-opened-flag {
      display: flex;
      align-items: center;
      column-gap: 8px;
      letter-spacing: 0px;
      color: #569A58;
      font-size: 16px;
    }
  }
}

.survey-question-card {
  min-height: 600px;
  width: auto;
  width: 575px;
  min-width: 320px;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  box-shadow: 0px 4px 16px #00000029;
  border-radius: 12px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  row-gap: 50px;

  &.rate-question-type {
    row-gap: 0;
  }

  &.heatmap-type {
    height: auto;
    width: auto;
    min-width: 575px;
    min-width: 600px;
  }

  .questionText {
    text-align: left;
    letter-spacing: 0px;
    color: #242836;
    font-size: 16px;
    font-weight: 600;
    align-self: baseline;
  }

  .answer-row {
    column-gap: 10px;
  }
}

.star-container {
  height: 80px;
  display: flex;
  flex-direction: column;
  align-self: baseline;
  row-gap: 5px;
  column-gap: 50px;
  flex-wrap: wrap;
  margin-left: 15px;

  .rate-container {
    display: flex;
    align-items: center;
  }

  .rate-label {
    margin-left: 10px;
    letter-spacing: 0px;
    color: #242836;
  }
}

.heatmap {
  width: 100%;
  height: 100%;
}

.no-access-block {
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 500;
}