.regular-checkbox-container {
  margin-bottom: unset;
  display: flex;
  align-items: center;
  gap: 4px;

  .small {
    width: '12px';
    height: '12px';
  };
  .medium {
    width: '16px';
    height: '16px';
  };
  .large {
    width: '20px';
    height: '20px';
  };
}