.step_item_container {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: 8px;
  justify-content: center;
  align-items: center;
  background-color: #EFF3FC;
  border-top: 3px solid;
  border-bottom: 3px solid;
  padding: 12px 0;
  &.selected {
    background-color: #131F6B;
    color: #fff;
    border-color: #545AD8;
    .category {
      color: #fff;
    }
  }
  &.small {
    width: 80px;
    height: 80px;
  }
  &.privacy {
    border-color: #545AD8;
  }
  &.security {
    border-color: #54D8A8;
  }

  svg {
    display: none;
  }

  &:hover {
    svg {
      display: block;
    }
  }

  .remove_step_btn {
    position: absolute;
    top: -15px;
    right: -12px;
  }
  .step_drag_btn {
    position: absolute;
    bottom: -19px;
    left: calc(50% - 16px);
  }
  .privacy {
    color: #545AD8
  }
  .security {
    color: #54D8A8;
  }
  
  .step_title {
    font-size: 16px;
    font-weight: 700;
    line-height: 18px;
    text-align: center;
  }
  .category {
    font-size: 12px;
    font-weight: 700;
    line-height: 14px;
    text-align: center;
    margin-bottom: unset;
  }
  .category_input {
    width: 80%;
  }
  
}

.view_step_item {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 8px;
  width: 136px;
  background-color: #EFF3FC;
  padding: 8px 16px;
  cursor: pointer;
  border-top: 3px solid;
  border-bottom: 3px solid;
  margin-left: 16px;
  margin-bottom: 16px;

  .step_number {
    margin-bottom: unset;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 12px;
  }
  .group_txt {
    color: #777777;
    font-size: 10px;
    font-weight: 400;
  }
  .group_name {
    font-size: 12px;
    font-weight: 700;
    text-transform: capitalize;
  }
  .time_limit_block {
    display: flex;
    column-gap: 4px;
    font-size: 8px;
    font-weight: 500;
  }
  &.inner_white {
    color: #fff;
    * {
      color: #fff;
    }
  }
}