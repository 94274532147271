.input-group {
  border-bottom: 2px solid #d3d4d7;
  padding-bottom: 8px;
  margin: 5px 0 35px 0;
  display: flex;
  align-items: center;
  min-width: 300px;
  input {
    border: none;
    background: transparent;
    outline: none;
    font-size: 17px;
    height: 23px;
    padding: 0 15px;
  }
  .icon-area {
    width: 50px;
    height: 23px;
    border-right: 2px solid #d3d4d7;
    justify-content: center;
    align-items: center;
    display: flex;
  }

  &.has-error {
    input {
      color: #ff7e93;
    }
  }

  &.no-icon {
    margin: initial;
    padding-bottom: 5px;
    input {
      width: 100%;
      height: 25px;
      padding-left: 0;
    }
  }
  &.has-placeholder {
    position: relative;
    padding-left: 100px;
    .fixed-placeholder {
      font-size: 17px;
      color: #797979;
      position: absolute;
      width: 100px;
      left: 0;
    }
  }
  &.no-min-w {
    min-width: initial !important;
  }
}
.no-min-w {
  min-width: initial !important;
}

.form-input-row {
  .form-input-row-actions {
    display: flex;

    .btn {
      padding: 6px 18px !important;
      height: 31px !important;
      font-size: 12px !important;
    }

    .btn-outline-blue {
      background-color: #fff;
    }
  }
}