.close-preview {
  background-color: #000000B2;
  display: flex;
  max-width: 616px;
  color: #fff;
  height: 67px;
  position: absolute;
  top: 35px;
  left: calc(50vw - 308px);
  padding: 16px;
  border-radius: 9px;
  gap: 8px;
  z-index: 9999999999;
  .desc {
    font-size: 11px;
    font-weight: 500;
  }
  .btn-close {
    display: flex;
    align-items: center;
    margin-left: 16px;
    flex-shrink: 0;
    width: 146px;
    height: 35px;
    gap: 8px;
    font-size: 12px;
    font-weight: 400;
  }
}