.viewer {
  position: relative;
  background: #181818;
}

.genuie-modes {
  position: absolute;
  right: 0;
  top: 0;
  z-index: 10;
  margin-top: 44px;
  margin-right: 75px;
  padding: 5px;
  button {
    position: relative;
    margin-bottom: 25px;
    span {
      position: absolute;
      height: 15px;
      background: #EB4D4D 0% 0% no-repeat padding-box;
      border-radius: 2px;
      color: #FCFCFC;
      font-size: 10px;
      line-height: 15px;
      padding: 0 6px;
      top: -10px;
    }
  }
  button:focus {
    box-shadow: none;
  }
}

.control-area, .steps-area {
  position: relative;
  height: 48px;
  background: #181818;
}

.viewer-area {
  max-width: 100%;
  flex: 1;
  position: relative;
  height: 100%;
  max-height: calc(100vh - 123px);
  padding: 0 4px;
  &.fullscreen {
    flex: 1;
    max-height: calc(100vh - 48px);
  }

  &.has-steps-and-header {
    max-height: calc(100vh - 96px - 80px);
    &.fullscreen {
      max-height: calc(100vh - 96px - 80px);
    }
  }

  .birads_preview_container {
    position: absolute;
    left: 15px;
    top: 20px;
    width: 300px;
    max-height: 450px;
    overflow: auto;
    background-color: white;
    z-index: 1;
    border-radius: 7px;
    padding: 16px 16px 0 16px;

    .content {
      .general_text {
        letter-spacing: 0px;
        color: #131F6B;
        font-weight: bold;
        font-size: 13px;
      }

      .answer_title {
        letter-spacing: 0px;
        color: #131F6B;
        font-weight: bold;
        font-size: 15px;
      }

      ul {
        list-style: none;
      }

      .description {
        font-size: 16px;

        &::before {
          content: "• ";
          color: #131F6B;
          font-size: 1.3em;
          line-height: 0.9em;
        }
      }
    }

    &.empty {
      min-height: 200px;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding-top: 30px;
      gap: 10px;
      color: #a4a4a4;
      font-size: 20px;
      font-weight: bold;
    }
  }
}

.viewport-element {
  position: relative;
  flex: 1;
  // margin: 0 3px;
  font-size: 14px;
  overflow: hidden;
  // border-radius: 10px;
  &.maximize {
    position: absolute;
    left: 0;
    width: 99.5% !important;
    z-index: 1000;
    background: black;
  }
  .dicom-number {
    padding: 5px 10px;
    width: 100%;
    position: absolute;
    color: #fff;
    opacity: .8;
  }
  .resolution-info {
    padding: 5px;
    width: 100%;
    position: absolute;
    color: #a9a9a9;
    bottom: 25px;
  }
  .scale-rules {
    position: absolute;
    bottom: 10px;
    width: 100%;
    text-align: center;
    font-size: 12px;
    color: #a9a9a9;
    .mili {
      color: #a9a9a9;
      font-weight: bold;
    }
    .rule {
      min-width: 30%;
      width: 150px;
      height: 10px;
      border-bottom: 1px solid #a9a9a9;
      margin: 0 auto;
      span {
        display: block;
        width: 1px;
        height: 6px;
        background: #a9a9a9;
        &:first-child, &:last-child {
          height: 10px;
        }
      }
    }
  }
  &.left {
    text-align: right;
  }
  &.right {
    text-align: left;
    .thumbnail-view {
      right: 10px;
      left: initial;
    }
  }
}

.tomo-switch {
  position: absolute;
  bottom: 10px;
  width: 100%;
  text-align: center;
  > div {
    border-radius: 8px;
    overflow: hidden;
  }
  button {
    border: none;
    color: #fff;
    background: #282828;
    font-weight: bold;
    width: 50px;
    &.active {
      background: #00919C;
    }
    &:focus {
      box-shadow: none;
      outline: none;
    }
    &:disabled {
      opacity: 0.5;
    }
  }
}

:-webkit-full-screen {
  position: fixed;
  bottom: 0;
  top: 0;
  width: 100%;
  height: 100%;
  nav {
    display: none !important;
  }
  .users-header, .notification-block {
    display: none !important;
  }
  div.has-header {
    margin-top: 0 !important;
    padding-top: 0 !important;
  }
  main {
    margin: 0;
    padding: 0;
    flex: 1;
    max-width: initial;
    position: fixed;
    bottom: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
}

.thumbnail-view {
  position: absolute;
  left: 10px;
  bottom: 10px;
  background: rgba(0,0,0,0.7);
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid;
  border-radius: 7px;
  .core {
    position: absolute;
    background: #ffffffad;
  }
  .image {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0%;
    background-size: contain;
  }
}

.sw-icon {
  height: 35px;
}

.switcher {
  color: #a9a9a9;
  svg.sw-icon {
    height: 20px;
  }
  p {
    margin: 0 5px;
  }
  span {
    font-weight: bold;
  }
  .btn {
    svg.sw-icon {
      path {
        fill: #00919C !important;
      }
    }
    &:focus {
      box-shadow: none;
    }
    &:disabled {
      .sw-icon {
        opacity: .6;
      }
      svg.sw-icon {
        path {
          fill: #a9a9a9 !important;
        }
      }
    }
    &.highlighted {
      border: 1px solid #00919C;
    }
    &.case-btn {
      margin-bottom: 1px;
      padding: 2px 6px;
    }
  }
}

.stack-control {
  height: calc(96% - 64px);
  position: absolute;
  right: 20px;
  top: 2%;
  border: 1px solid #707070;
  width: 32px;
  background: #181818;
  opacity: 0.7;

  .play-btn {
    padding: 0;
    width: 100%;
    text-align: center;
    height: 100%;
    background: #2a2a2a;
    border-radius: 0;
    border: none;
    text-indent: 2px;
    svg {
      width: 15px;
    }
    &:focus {
      box-shadow: none;
      outline: none;
    }
  }

  .settings-btn {
    position: relative;
    background: #2a2a2a;
    text-align: center;
    border-bottom: 1px solid #707070;
    height: 30px;
    padding-top: 2px;
    img.speed-icon {
      width: 80%;
    }
    .settings-options {
      z-index: 1000;
      left: 100%;
      top: -1px;
      position: absolute;
      visibility: hidden;
      background: #1d1d1d;
      border: 1px solid #4e4e4e;
      button {
        color: #fff;
        margin: 0;
        padding: 3px 8px;
        &.active {
          color: #00919c;
        }
      }
    }
    &:hover {
      .settings-options {
        visibility: visible;
      }
    }
  }

  .stack-word {
    height: 28px;
    line-height: 28px;
    color: #fff;
    width: 100%;
    text-align: center;
    font-size: 15px;
  }

  .stack-num {
    color: #fff;
    font-size: 10px;
    text-align: center;
    border-top: 1px solid #4e4e4e;
    padding: 3px 0;
  }

  .rangeslider-vertical {
    height: calc(100% - 137px);
    max-width: 26px;
    // background-image: linear-gradient(0deg, #181818 45.45%, #434343 45.45%, #434343 50%, #181818 50%, #181818 95.45%, #434343 95.45%, #434343 100%);
    // background-size: 22.00px 22.00px;
    border-radius: 3px;
    margin-top: 0;
    margin-bottom: 0;
    .rangeslider__fill {
      background: transparent;
    }
    .rangeslider__handle {
      font-size: 15px;
      text-align: center;
      line-height: 23px;
      height: 23px;
      background: #bababa;
      border-color: #bababa;
      color: #000000;
      border-radius: 0;
      border-width: 0;
      font-weight: bold;
      width: 38px;
      left: -6px;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
      &:focus {
        outline: none;
      }
    }
    .rangeslider__labels {
      width: 10px;
    }
  }

  .stack-tooltip {
    display: none;
    position: absolute;
    right: 43px;
    top: -10px;
    max-width: 200px;
    padding: 10px 15px 8px;
    border-radius: 4px;
    font-size: 12px;
    line-height: 16px;
    background: #131f6b;
    color: #eff4fb;
    min-width: 100px;
    text-align: center;

    &::after {
      content: '';
      width: 12px;
      height: 12px;
      position: absolute;
      background: inherit;
      z-index: 1;
      top: 50%;
      right: -6px;
      transform: translateY(-50%) rotate(45deg);
    }

  }

  .stack-movie-buttons {
    width: 30px;
    height: 64px;
    button {
      width: 100%;
      height: 30px;
      border: 1px solid #707070;
      background: #151515;
      color: #fff;
      border-radius: 0;
      box-sizing: content-box;
      padding: 0;
      margin-top: 2px;
      margin-left: -1px;
    }
  }

  &.left {
    left: 20px;
    right: initial;
    .stack-tooltip {
      right: initial;
      left: 43px;
      &::after {
        right: initial;
        left: -6px;
        transform: translateY(-50%) rotate(45deg);
      }
    }
  }
  &.right {
    .settings-btn {
      .settings-options {
        right: 100%;
        left: initial;
      }
    }
  }

  &:hover {
    opacity: 1;
    &.is-loading {
      .stack-tooltip {
        display: block;
      }
    }
  }

}

.view-steps, .view-tools {
  button {
    img {
      opacity: 1;
    }
    &.active {
      img {
        opacity: 1;
      }
    }
  }
}

.view-tools {
  button.active {
    background: #000;
    border-radius: 5px;
  }
}

.view-steps {
  button.active {
    border-bottom: 2px solid #00919C;
    border-radius: 0;
    padding-bottom: 7px;
    margin-top: 4px;
  }
}

.text-light-blue {
  color: #00919C;
}

.help-block {
  cursor: pointer;
  height: 38px;
  width: 160px;
  background: #181818;
  a, button {
    text-decoration: none;
    color: #b5bef6;
    font-size: 17px;
    &:hover {
      text-decoration: none;
      color: #b5bef6;
    }
  }
  svg {
    path {
      fill: #b5bef6;
    }
  }
  &:hover {
    background: #212121;
  }
}

.maximize-icon {
  // position: absolute;
  // left: 5px;
  // top: 5px;
  padding: 0;
  width: 20px;
}

.cursor-pan {
  cursor: url('../../../../assets/toolspng/5a.png'), auto;
}

.cursor-izoom {
  cursor: url('../../../../assets/toolspng/6a.png'), auto;
}
.cursor-mzoom {
  cursor: url('../../../../assets/toolspng/8a.png'), auto;
}
.cursor-fullzoom {
  cursor: url('../../../../assets/toolspng/7a.png'), auto;
}
.cursor-maximize {
  cursor: url('../../../../assets/toolspng/3a.png'), auto;
}
.cursor-arrow {
  cursor: url('../../../../assets/toolspng/2a.png'), auto;
}
.cursor-wlevel {
  cursor: url('../../../../assets/toolspng/9a.png'), auto;
}
// .cursor-genuine-shape-1 {
//   cursor: url('../../../../assets/shapes/shape-cross.svg'), auto;
// }
// .cursor-genuine-shape-2 {
//   cursor: url('../../../../assets/shapes/shape-triangle.svg'), auto;
// }
// .cursor-genuine-shape-3 {
//   cursor: url('../../../../assets/shapes/shape-flake.svg'), auto;
// }

.stack-lines {
  height: calc(100% - 137px);
  position: absolute;
  left: 0;
  top: 88px;
  width: 100%;
  .line {
    position: relative;
    display: block;
    // height: 1px;
    flex: auto;
    width: 100%;
    // background: #434343;
    border-top: 1px solid #434343;
    &.has-point:before {
      content: '';
      position: absolute;
      width: 8px;
      height: 8px;
      border-radius: 100%;
      background: #EB4D4D;
      left: -6px;
      z-index: 100;
    }
    &:nth-last-child(1) {
      border-bottom: 1px solid #434343;
    }
  }
}

.stack-control.genius-ai-mode {
  height: 96%;
  .stack-lines {
    height: calc(100% - 201px);
    .line {
      &.has-primary-slice {
        background: #EB4D4D;
        border-top-color: #0d0d0d;
      }
      &.has-prepost-slice {
        background: #EABDBD;
        border-top-color: #0d0d0d;
      }
    }
  }


  .rangeslider-vertical  {
    height: calc(100% - 201px);
    .rangeslider__handle {
      border-width: 3px;
      height: 22px;
      background: #00b5ed40;
      border-color: #00B5ED;
      line-height: 15px;
      color: #fff;
      width: 36px;
      left: -5px;
      font-size: 13px;
    }
  }
}

.viewer-no-image {
  font-size: 20px;
  background: black;
  height: 100%;
}

.genuie-button-area {
  position: absolute;
  top: 6%;
  z-index: 10;
  &.right {
    right: 75px;
  }
  &.left {
    left: 15px;
  }
  .genuie-modes-items {
    z-index: 10;
    button {
      cursor: default;
      position: relative;
      margin-top: 25px;
      span {
        position: absolute;
        height: 15px;
        background: #EB4D4D 0% 0% no-repeat padding-box;
        border-radius: 2px;
        color: #FCFCFC;
        font-size: 10px;
        line-height: 15px;
        padding: 0 6px;
        top: -10px;
      }
    }
    button:focus {
      box-shadow: none;
    }
  }
}

.pre-post-slices {
  position: absolute;
  top: 12px;
  left: 50%;
  margin-left: -95px;
  button.add-slice-btn {
    width: 190px;
    height: 40px;
    border-radius: 2px;
    background: #151515;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
  }
}

.pre-post-modal {
  width: 350px;
  height: 300px;
  padding: 20px 16px;

  button.slice-tab {
    padding: 0;
    font-size: 14px;
    color: #777777;
    border-radius: 0;
    padding-bottom: 4px;
    &.active {
      color: #131F6B;
      border-bottom: 3px solid #131F6B;
    }
    &:focus {
      outline: none;
      box-shadow: none;
    }
  }

  .slice-form {
    margin-top: 18px;
    background: #EFF4FB 0% 0% no-repeat padding-box;
    border-radius: 5px;
    height: 50px;
    padding: 0 20px;

    button.btn-slice-change {
      width: 24px;
      height: 24px;
      padding: 0;
      text-align: center;
      border-radius: 100%;
      background: #131F6B;
      color: #fff;
      line-height: 14px;
      &:disabled {
        background: #A7A7A7;
      }
    }

    input {
      height: 30px;
      width: 96px;
      font-size: 14px;
      text-align: center;
      border-radius: 5px;
      border: none;
      &:focus {
        box-shadow: none;
        outline: none;
      }
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
}

.playing-mode-alert {
 position: absolute;
 font-size: 14px;
 color: #00B5ED;
 left: 50%;
 margin-left: -69px;
 top: 12px;
}

.tool-separator {
  margin: 0 8px 0 5px;
  width: 1px;
  height: 30px;
  background: #292929;
}

.viewer-modal {
  width: calc(90% - 230px);
  height: 90%;
  color: #a9a9a9;
  background: #191818;
  margin-left: 115px;
  z-index: 10000;
}

header[class^="MuiPaper"] {
  background-color: transparent !important;
  button {
    outline: none !important;
  }
}

span[class^="MuiPrivateTabIndicator"] {
  background-color: #00b5ee !important;
}

.help-description {
  img {
    width: 30px;
  }
  button {
    border-radius: 3px;
    border: 2px solid #00b5ee;
    color: #fff;
    background-color: transparent;
    line-height: 20px;
    font-weight: bold;

    &:not(:last-child) {
      margin-right: 25px;
      position: relative;

      &::after {
        content: "+";
        display: inline-block;
        margin: 0 10px;
        color: #fff;
        position: absolute;
        left: 100%;
        top: 50%;
        transform: translateY(-50%);
      }
      &.btn-slash::after {
        content: "/";
        margin: 0 10px 0 12px;
      }
    }
  }
}

.no-display {
  display: none !important;
}

.snackbar-message, #client-snackbar {
  white-space: pre-line;
}

