.btn-confirm {
  font-size: 12px;
  letter-spacing: 0.24px;
}

.btn-confirm-primary {
  color: #131F6B;
}

.btn-confirm-danger {
  color: #EB4D4D;
}