.dashes_container {
  position: relative;
  display: flex;
  gap: 10px;
}

.dash_item {
  width: 30px;
  height: 6px;
  background: #B1B1B1;;
}