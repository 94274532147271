.patient_model_img {
  height: 400px;
  width: auto;
}

.section_title {
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 0.36px;
  color: #131F6B;
}

.room_preview_btn {
  width: 98px;
  height: 34px;
  font-size: 13px;
  font-weight: bold;
}

.preview_container {
  height: 100vh;
  width: 100vw;
  z-index: 99999999999999;
  position: fixed;
  left: 0;

  .btn_apply_config {
    position: absolute; 
    top: 12px; 
    left: 100px;
    background-color: rgb(220, 239, 249);
    color: rgb(31, 128, 183);
    border: 1px solid rgb(31, 128, 183);
    transition: .4s;
    height: 70px;
    border-radius: 10px;
    font-size: 20px;
    width: 200px;
    line-height: 1;
    opacity: 0.6;


    &:hover {
      background-color: rgb(31, 128, 183);
      color: #fff;
    }
  }
}

.form_counter_after_text {
  margin: 0 8px
}

.rooms_custom_radio_block {
  label {
    padding-left: 30px !important;
  }
}