.steps_block_container {
  width: 100%;
  height: 400px;
  border: 2px dashed #C2C6CC;
  border-radius: 2px;
}

.steps_block {
  max-width: 100%;
  width: fit-content;
  overflow-x: auto;
  overflow-y: hidden;
  height: 90%;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 24px 24px 0 32px;
}

.quiz_prop_buttons {
  row-gap: 12px;
  
  .first_row {
    column-gap: 9px;
  }
}

.right_side_questions_block {
  width: 100%;
  background-color: #EFF4FB;
  padding: 14px 0;
  display: flex;
  flex-direction: column;
  font-size: 13px;
  font-weight: 400;
  color: #242836;
  cursor: pointer;

  .row_item {
    display: flex;
    column-gap: 16px;
    padding: 6px 14px;
    border-left: 3px solid transparent;
    border-right: 3px solid transparent;

    &:hover {
      background-color: #fff;
      &.have_border_on_hover {
        border-color: rgb(84, 216, 168);
      }
    }

    &.selected {
      background-color: #fff;
    }
  }
}

.g_accordions_block {
  margin-bottom: 32px;
}

.qg_title_row {
  display: flex;
  gap: 16px;
  margin-bottom: 22px;

  h6 {
    margin-bottom: unset;
  }
}

.question_idx {
  position: absolute;
}

.show_more_block {
  width: 100%;
  height: 57px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #EFF4FB;
  border-bottom: 2px solid;

  a {
    color: #2B2D42;
    text-decoration: underline;
  }
}

.images_columns_container {
  display: flex;
  gap: 8px;
  width: 100%;
  overflow-x: auto;
  padding-bottom: 20px;
}

.quiz_view_imgs_column_container {
  width: 196px;
  height: 280px;
  border-radius: 6px;
  overflow: hidden;
  flex-shrink: 0;

  .head_block {
    width: 100%;
    height: 35px;
    display: flex;
    align-items: center;
    padding-left: 16px;
    background-color: #131F6B;
    color: #fff;
  }
  .body_block {
    width: 100%;
    height: calc(100% - 35px);
    background-color: #fff;
    padding: 14px;
    .scrollable_block {
      width: 100%;
      max-height: 100%;
      display: flex;
      flex-wrap: wrap;
      column-gap: 6px;
      row-gap: 8px;
      overflow-y: auto;
      .img_item {
        width: 80px;
        height: 56px;
        border-radius: 6px;
        border: 1px solid #EBEBEB;
        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
        }
      }
    }
  }
}

