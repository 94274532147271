.table-custom {
  border-collapse: separate;
  border-spacing: 0 8px;
  font-family: 'Open Sans', sans-serif;
  font-weight: 400;
  font-size: 13px;
  line-height: 17px;

  th, td {
    padding: 0;
    border: none !important;
    vertical-align: middle !important;

    &:first-child:not(:last-child) {
      &.col-index {
        text-align: center;
      }
    }

    &.cell-fixed {
      &__sm {
        max-width: 150px;
      }
      &__md {
        max-width: 170px;
      }
      &__lg {
        max-width: 200px;
      }
      &__xlg {
        max-width: 250px;
      }
      &__xxlg {
        max-width: 350px;
      }
    }

    &:not(:last-child) {
      position: relative;

      &::after {
        content: '';
        display: block;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        width: 1px;
        background: #bac3d0;
        height: 40px;
      }
    }
  }

  th {
    padding: 5px 14px;

    &:not(:last-child)::after {
      height: 21px;
    }
  }

  td {
    background: #eff4fb;
    padding: 16px 14px;

    &:first-child:last-child {
      border-radius: 5px;
    }

    &:first-child:not(:last-child) {
      border-top-left-radius: 5px;
      border-bottom-left-radius: 5px;
    }

    &:last-child:not(:first-child) {
      border-top-right-radius: 5px;
      border-bottom-right-radius: 5px;
    }
  }

}

.status-pointer {
  display: inline-block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  border: 2px solid #fff;

  &__success {
    background-color: #6BCC71
  }
  &__warning {
    background-color: #FFE400
  }
  &__danger {
    background-color: #D53838
  }
}

.table-no-right-borders {
  * {
    border-right: none !important;
  }
}
.table-filter-btns {
  padding-left: 16px;

  .btn {
    min-width: 133px;
    &.active {
      background-color: $primary-color !important;
      color: #fff !important;
    }
  }
}

.table-square-img {
  width: 40px;
  height: 40px;
  object-fit: cover;
}
