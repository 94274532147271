.date_part_container {
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

}

.switch_container {
  width: fit-content;
  border-right: 1px solid #91939A;
  margin-right: 24px;
  margin-left: 11px;

  .MuiFormControlLabel-root {
    margin-bottom: 0;
  }
}

.result {
  padding: 0 13px 0 0 !important;
}

.assigned-entity-edit-container {
  height: 32px;
  width: 100%;
  display: flex;
  column-gap: 12px;
  align-items: center;

  .btn {
    font-size: 13px;
  }

  .select-box, .result {
    flex: 1;
  }
}