.small-count-container {
  display: flex;
  column-gap: 5px;

  &.disabled {
    pointer-events: none;
    opacity: 0.5;
  }

  .cube-radiused {
    height: 32px;
    width: 80px;
    
    background: #F4F7FC 0% 0% no-repeat padding-box;
    border-radius: 5px;
    padding: unset;
    &.small {
      width: 48px;
    }
  }
}