.redesigned-select-container {
  border-bottom: none;
  &.outlined-gray {
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 6px;
    width: 100%;

    label {
      color: $gray-700;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 0;
    }
    select {
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      margin-left: 1px;
      padding: 12px 16px 12px 12px;
      align-self: center;
      border-radius: 8px;
      background-color: #fff;
      border: 1px solid $gray-300;
      box-shadow: 0px 1px 2px 0px #1018280D;
      width: 100%;
      height: 48px;
      background-image: url('../../assets/icons/select-arrow-down.svg');
      background-repeat: no-repeat;
      background-position: right 12px center;
      background-size: 12px;
    }
    select:focus {
      outline: none;
    }
  }
}
