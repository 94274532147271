@import url('https://fonts.googleapis.com/css?family=Roboto:300,400,500,600,700,900&display=swap');
@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,500,600,700,800&display=swap');

@import '~video-react/styles/scss/video-react';
@import "~bootstrap/scss/bootstrap.scss";

@import 'utils/variables';

@import 'forms/input-group';
@import 'forms/labels';

@import 'common/type';
@import 'common/button';
@import 'common/classes';
@import 'common/tables';
@import 'common/fields';
@import 'common/dateRangePicker';
@import 'common/smallCounter';
@import 'common/warningDialogue';
@import 'common/editingInput';
@import 'common/listActionButtons';
@import 'common/userSelect';
@import 'common/addDeviceModal';
@import 'common/dashComponent';
@import 'common/exportTableData';
@import 'common/radioBox';
@import 'common/regularCheckbox';
@import 'common/quizStepItem';
@import 'common/regularInput';
@import 'common/rightSidebar';
@import 'common/imageCategoryDnDBlock';
@import 'common/progresses';
@import 'common/input';
@import 'common/select';
@import 'common/textarea';
@import 'common/landingFooter';
@import 'common/close-preview';
@import 'common/rightDetailsBlock';
@import 'common/accordionTable.scss';
@import 'common/entity-item';
@import 'common/gaidMeasurementDialog';
@import 'common/alertMessage';
@import 'common/gaidAssociationsBlock';
@import 'common/virtualCopyRow';
@import 'common/editingInputWithPostfix';

@import 'layouts/sidebar';
@import 'layouts/bar-chart';
@import 'layouts/common';
@import 'layouts/loading';
@import 'layouts/modal';
@import 'layouts/accordion';
@import 'layouts/notification';
@import 'layouts/calendar';
@import 'layouts/dropdown';

@import 'pages/auth-pages';
@import 'pages/users-pages';
@import 'pages/users-pages';
@import 'pages/user-progress';
@import 'pages/course-view';
@import 'pages/lesson_create';
@import 'pages/dash-page';
@import 'pages/protocol';
@import 'pages/course-preview-page';
@import 'pages/license';
@import 'pages/public-pages';
@import 'pages/survey-web-view';
@import 'pages/countries';
@import 'pages/regions';
@import 'pages/rooms';
@import 'pages/user-info';
@import 'pages/subplan-create';
@import 'pages/email-verification';
@import 'pages/quiz';
@import 'pages/quizQuestions';
@import 'pages/quizView';
@import 'pages/dicomViewer';
@import 'pages/product';
@import 'pages/product-page';
@import 'pages/product-page-preview';
@import "pages/marketing-spotlight";


body {
  color: #242836;
  font-size: $font-size-main;
  // line-height: $line-height-main;
  min-width: 1224px;
}

@media (max-width: 1224px) {
  body {
    min-width: auto;
  }
}

main {
  margin-left: 230px;
  min-width: 800px;
  z-index: 1;
  position: relative;
  &.kol {
    .users-header {
      padding: 0 210px 0 15px !important;
    }
    .header-section {
      padding-right: 120px !important;
    }
  }
  &.has-files {
    .users-header {
      padding: 0 210px 0 15px !important;
    }
    .header-section {
      padding-right: 120px !important;
    }
  }
  &.no-sidebar {
    margin-left: unset;
  }
}

img {
  max-width: 100%;
}

a.link {
  color: #131f6b;
  font-weight: 500;
  text-decoration: none;
}

.opc-3 {
  opacity: .3;
}

.opc-2 {
  opacity: .2;
}

.opc-full {
  opacity: 1;
}

.opc-0 {
  opacity: 0;
}

.fw-300 {
  font-weight: 300 !important;
}

.fw-400 {
  font-weight: 400 !important;
}

.fw-500 {
  font-weight: 500;
}

.fw-600 {
  font-weight: 600;
}

.fw-700 {
  font-weight: 700;
}

.pointer {
  cursor: pointer;
}

.not-allowed {
  cursor: not-allowed;
}

.m-back {
  margin-right: -15px;
  margin-left: -15px;
}

.section-header {
  margin-bottom: 20px;
  h2, h3 {
    margin: 0;
    color: #242836;
  }

  h2 {
    font-size: 20px;
  }

  h3 {
    font-size: 17px;
  }

  a {
    text-decoration: none;
    font-size: 12px;
    color: #131f6b;
  }
}

.breadcrumbs {
  font-size: 17px;
  color: #131f6b;
  a {
    color: #131f6b;
    text-decoration: none;
  }
  span:last-child {
    font-weight: bold;
  }
}

.blue-box {
  position: relative;
  min-height: 45px;
  background: #eff4fb;
  border-radius: 5px;
  padding: 15px;

  p {
    font-weight: bold;
    color: #242836;
    font-size: 16px;
  }

  .info {
    font-size: 16px;
    color: #242836;
    font-weight: 100;
  }

  input, textarea, select {
    height: 36px;
    border: none;
    background: #ffffff;
    font-size: 15px;
    padding: 5px 10px;
    outline: none;
    border-radius: 5px;
  }

  textarea {
    min-height: 120px;
    resize: none;
  }

  .edit-btn {
    position: absolute;
    right: 20px;
  }
}

.more-btn {
  display: block;
  height: 35px;
  min-width: 55px;
  background: #fff;
  color: #222222;
  font-size: 12px;
  border-radius: 5px;
  text-align: center;
  line-height: 35px;
  text-decoration: none;
  border: none;
  padding: 0 4px;
  &:hover {
    text-decoration: none;
    color: #222222;
  }
  svg {
    fill: #131f6b;
    width: 7px;
  }
  &.link-color {
    color: #131f6b;
  }
  &.small {
    width: initial;
    height: 28px;
    font-size: 10px;
    padding: 2px;
    line-height: 25px;
  }
  &.danger {
    color: #dc3545;
    border: 1px solid;
    svg {
      fill: #dc3545;
    }
    &:focus {
      outline: none;
    }
  }
}

.link-color {
  color: #131f6b;
}

.pagination {
  justify-content: center;
  margin-top: 20px;
  width: 100%;
  li {
    margin: 0 8px;
    a {
      background: #eff4fb;
      font-size: 10px;
      text-decoration: none;
      cursor: pointer;
      color: #131f6b;
      width: 20px;
      height: 20px;
      line-height: 20px;
      border-radius: 100%;
      text-align: center;
      display: inline-block;
      font-weight: bold;
      &:focus {
        box-shadow: none;
        outline: none;
      }
    }
    &.active a {
      background: #131f6b;
      color: #fff;
    }
  }
  .previous, .next {
    display: none;
  }
  &.show-arrow {
    .previous, .next {
      display: block;
      a {
        background: none;
      }
      &.disabled {
        a {
          opacity: 0.8;
          cursor: not-allowed;
        }
      }
    }
  }
}

.rotate-180 {
  transform: rotate(180deg);
}

.avatar-container,
.user-avatar {
  width: 107px;
  height: 107px;
  border-radius: 50%;
  overflow: hidden;
  object-fit: cover;
  object-position: top;
  position: relative;

  label {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    margin: 0;
    cursor: pointer;
    background: rgba(0, 40, 255, 0.4);
    display: none !important;

    span {
      line-height: 20px;
      color: #fff;
      margin-bottom: 15px;
    }
  }

  &:hover label {
    display: flex !important;
  }

}

.modal-default {
  padding: 25px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #eff4fb;
  box-shadow: rgba(0, 0, 0, 0.14) 0px 0px 25px 1px;
}

.subscription-modal {
  width: 350px;
}

.button-times {
  width: 18px;
  height: 18px;
  position: absolute;
  top: 3px;
  right: 4px;
  border: none;
  background: #eff4fb;

  &::before,
  &::after {
    content: '';
    display: block;
    width: 100%;
    height: 2px;
    background-color: #242836;
    transform-origin: center;
    position: absolute;
    top: 50%;
    left: 50%;
  }

  &::before {
    transform: translateX(-50%) rotate(45deg)
  }

  &::after {
    transform: translateX(-50%) rotate(-45deg)
  }

  &.dark {
    background: transparent;
    &::before,
    &::after {
      background-color: #eff4fb;
    }
  }
}

.no-radius {
  border-radius: 0 !important;
}

.mt--20 {
  margin-top: -20px;
}

.weight-600 {
  font-weight: 600;
}

.w-100p {
  width: 100px;
}

.scroll-height {
  max-height: 120px;
  overflow-y: auto;
}

.certificate-page {
  background: #eff4fb;
}

.rating {
  unicode-bidi: bidi-override;
  direction: rtl;
  position: relative;
  font-size: 14px;

  span {
    &:not(:first-child) {
      margin-right: 3px;
    }
  }

  &__completed:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
  }
}
.rating > span:hover:before,
.rating > span.active:before,
.rating > span:hover ~ span:before,
.rating > span.active ~ span:before {
  content: "\2605";
  position: absolute;
  color: rgba(19, 31, 106, 0.19);
  text-shadow: 0 0 1px;
}

.rating > span:not(.active):hover:before,
.rating > span:not(.active):hover ~ span:before{
  color: rgba(19, 31, 106, 0.19);
  text-shadow: 0 0 1px;
}

.rating > span.active:before,
.rating > span.active ~ span:before {
  color: #131f6b;
  text-shadow: 0 0 1px;
}

.rating > span:hover:before {
  content: "\2605";
  position: absolute;
  color: rgba(19, 31, 106, 0.19);
  text-shadow: 0 0 1px;
}

.certificate-item-old {
  position: relative;
  width: 250px;
  .hover-item {
    display: none;
    position: absolute;
    top: 13px;
    left: 16px;
    right: 16px;
    bottom: 18px;
    border-radius: 7px;
    color: white;
    background: rgba(0, 0, 0, 0.44);
    .buttons-area {
      background: #fff;
      border-top-left-radius: 7px;
      border-top-right-radius: 7px;
      button, a {
        white-space: nowrap;
        color: #131f6b;
      }
    }
  }
  &:hover {
    .hover-item {
      display: flex;
    }
  }
}

.has-header {
  padding-top: 99px !important;
  &.no-top-padding {
    padding-top: 75px !important;
  }
}

.text-elips {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.ReactModal__Overlay {
  z-index: 1000;
}

.course-users-modal {
  min-width: 900px;
  min-height: 500px;
  .users-list {
    max-height: 500px;
    overflow: auto;
    width: 100%;
  }
  .content {
    overflow: auto;
  }
}

.text-underline {
  text-decoration: underline;
}

.fz-16 {
  font-size: 16px;
}

.fz-17 {
  font-size: 17px;
}

.fz-40 {
  font-size: 40px;
}

.min-100 {
  min-width: 100px;
}

.min-50 {
  min-width: 50px;
}

.min-60 {
  min-width: 60px;
}

.min-120 {
  min-width: 120px;
}

.min-130 {
  min-width: 130px;
}

.password-modal {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 3;
  background: rgba(226, 226, 226, 0.8);

  .modal-content {
    margin-top: 10vh;
    padding: 30px 50px;
    width: unset;
    background: #fff;
    box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1);
  }

  .modal-header {
    margin: -30px -50px 0;
    padding-bottom: 0;
    border: none;
  }

  .modal-close {
    width: 20px;
    height: 20px;
    position: relative;
    cursor: pointer;

    &:before,
    &:after {
      content: '';
      display: block;
      width: 2px;
      height: 20px;
      transform-origin: center;
      position: absolute;
      top: 50%;
      left: 50%;
      background: #131f6b;
    }

    &:before {
      transform: translate(-50%, -50%) rotate(45deg);
    }

    &:after {
      transform: translate(-50%, -50%) rotate(-45deg);
    }
  }
}

.upload-file-form {
  label {
    background: #fff;
    width: 100%;
    height: 30px;
    border-radius: 5px;
    padding: 0 10px 0 15px;
    margin: 0;
    color: #495057;
    font-weight: normal;
    cursor: pointer;
  }
  span {
    color: #131F6B;
  }
}

.selected-blue {
  color: #131F6B;
}

.report-success-status {
  h4 {
    font-size: 20px;
    color: #242836;
  }
  p {
    font-size: 21px;
    color: #777777;
    line-height: 33px;
    font-weight: normal;
    font-weight: 200;
  }

  &.error {
    h4 {
      color: #EB4D4D;
    }

    button.btn.btn-blue {
      background: #E88989 !important;
      color: #fff !important;
    }
  }

}

.editor-toolbar.fullscreen {
  z-index: 10000 !important;
}

.CodeMirror.CodeMirror-fullscreen {
  z-index: 10000 !important;
}

.advanced-filters {
  padding: 0 16px;
  margin-bottom: 20px;
  > div {
    padding-bottom: 20px;
    &.bottom_bordered {
      border-bottom: 1px solid #D3D4D6;
    }
  }

  .active-filters {
    p {
      font-size: 12px;
      color: #222222;
      margin-right: 12px;
      margin-left: 5px;
      font-weight: 500;
    }
    .active-filter-block {
      height: 28px;
      border-radius: 5px;
      background: #EFF4FB;
      font-size: 12px;
      color: #131F6B;
      padding: 6px 8px;
      margin-right: 8px;

      .sub-filter {
        background: #dee4ec;
        border-radius: 4px;
        padding: 2px 5px;
      }
    }
  }
}

.filter-drop-down {
  .app-dropdown-header {
    font-size: 12px;
    color: #131F6B;
    margin-right: 0 !important;
    span {
      margin-right: 8px !important;
    }
    &:before,&:after {
      width: 8px;
      height: 8px;
    }
    &:before {
      top: 2px;
    }
    &:after {
      top: 4px;
    }
  }
  .app-dropdown-body {
    box-shadow: none;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
    border: 1px solid #131F6B;
    padding: 8px 0px;
    font-size: 14px;
    color: #242836;
    z-index: 100;

    .filter-search-block {
      padding: 10px 16px;
      input {
        border-radius: 5px;
        background: #EFF4FB;
        height: 31px;
        border: none;
        font-size: 13px;
      }
    }

    .drop-item {
      padding: 8px 16px;
      border: none;
    }
  }
  &.active {
    .app-dropdown-header {
      &:before {
        top: 10px !important;
      }
      &:after {
        top: 8px !important;
      }
    }
  }
  &.selected {}
}

.form-radio-circle {
  input.input-custom.is-radiobox[type=checkbox]:checked + label {
    &:after {
      content: '';
      position: absolute;
      width: 14px;
      height: 14px;
      background: #131F6B;
      left: 5px;
      top: 5px;
      border-radius: 100%;
    }
  }

  input.input-custom.is-radiobox + label {
    opacity: 1 !important;
    margin-bottom: 0;
    &:before {
      background: #fff !important;
      border: 1px solid #131F6B !important;
    }
  }

  input.input-custom.is-checkbox[type=checkbox]:checked + label {
    &:after {
      content: '✓';
      left: 0;
      width: 24px;
      position: absolute;
      color: #131F6B;
      line-height: 24px;
      text-align: center;
    }
  }

  input.input-custom.is-checkbox + label {
    font-weight: 500 !important;
    opacity: 1 !important;
    margin-bottom: 0;
    &:before {
      border-radius: 0;
      background: #fff !important;
      border: 2px solid #131F6B !important;
    }
  }
}


.drag-item {
  .action-buttons.has-remove {
    margin-right: -65px;
  }
  .btn {
    width: 50px;
    height: 50px;
    padding: 0;
    &.btn-drag, &.btn-check {
      background: #EFF3FC;
    }
    &.btn-remove {
      color: #fff;
      background: #E88989;
    }
  }
  &.drag-is-lesson-answer, &.drag-is-course-feedback {
    .action-buttons {
      .btn-drag {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
      }
    }
    > li {
      height: 50px;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  &.drag-is-lesson-answer {
    .action-buttons {
      margin-right: -133px !important;
    }
  }
  &.drag-is-course-feedback {
    .action-buttons {
      margin-right: -59px !important;
    }
  }
}

.react-confirm-alert-body {
  max-width: 314px;
  font-size: 14px;
  color: #242836 !important;
  padding: 20px 38px !important;
  letter-spacing: 0.28px !important;
  h1 {
    font-size: 17px;
    font-weight: bold;
    margin-bottom: 18px;
  }
  .react-confirm-alert-button-group {
    justify-content: flex-end;
    button {
      font-size: 12px;
      color: #131F6B;
      background: transparent;
      font-weight: bold;
      padding: 0;
      margin-left: 25px;
      margin-right: 0;
    }
  }
}

.email-editor {
  position: relative;
  .select_container {
    width: 140px;
    height: 40px;
    display: flex;
    align-items: center;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    border-top: 1px solid #c4c4c4;
    border-right: 1px solid #c4c4c4;

    max-width: 134px;

    select {
      padding: 4px;
      width: 100%;
    }

    .no_bg {
      background: none;
    }
  }
  .ck.ck-content.ck-editor__editable {
    min-height: 300px !important;
  }
  &.system_email_editor {
    .ck-sticky-panel__content {
      padding-right: 134px !important;
    }
  }
}

.text-word-break {
  word-break: break-all;
}

.switch_label {
  font-weight: normal;
  font-size: 14px;
}

.entity-image-upload {
  height: 104px;
  border: 1px dashed gray;
}

.fz-14 {
  font-size: 14px;
}

.back-button {
  margin-right: 10px;
  svg {
    height: 18px;
  }
}

.page-footer {
  background: #eff4fb;
}

.entity-block {
  border-radius: 5px;
  border: 1px solid #131F6B;
  background: #EFF4FB;
  padding: 22px;
  margin-bottom: 20px;
  img {
    width: 66px;
    height: 66px;
    margin-right: 40px;
    background: #fff;
  }
  p {
    min-width: 120px;
    font-size: 13px;
    color: #131F6B;
    margin: 0;
    font-weight: bold;
  }
  h4 {
    color: #131F6B;
    margin: 0;
    font-weight: bold;
    &.name {
      font-size: 24px;
    }
    &.address {
      font-size: 16px;
    }
  }
}

.plan-info-box {
  background: #eff4fb;
  padding: 25px 15px;
  border-radius: 5px;
  color: #242836;
  img {
    width: 100%;
  }
  p {
    font-size: 17px;
    font-weight: 500;
  }
  span {
    font-size: 13px;
    font-weight: 100;
  }
}

.subs-view-page {
  p.text-secondary {
    font-size: 17px;
  }
  .text-desc {
    font-size: 14px;
    color: #222222;
    font-weight: 300;
  }
}

.col-switch-btn {
  background: #EFF4FB;
  border-radius: 5px;
}

.list-remove-btn {
  height: 28px;
  min-width: 30px;
  padding: 0;
  text-align: center;
}

.tb-action-btn-sm {
  height: 28px;
  min-width: 30px;
}

.dicom-info-block {
  border: 1px solid #eff3fc;
  .card-header {
    background: #eff4fb;
    border-color: #eff3fc !important;
  }
  span {
    font-weight: 500;
  }
  div.col-6 {
    margin-bottom: 4px;
  }
}

.multi-select {
  width: 100%;
  > div {
    border: none;
  }
}

.multi-select-pre__single-value {
  font-weight: 400;
  color: #495057;
}

.opc-5 {
  opacity: .5;
}

.email-edit-info {
  font-size: 12px;
  color: #222222;
  letter-spacing: 0.24px;
  p {
    margin-bottom: 18px;
  }
  p.info-title {
    font-size: 14px;
    color: #131F6B;
    font-weight: bold;
    margin-bottom: 8px;
  }
  span {
    color: #131F6B;
    &.bold {
      font-weight: bold;
    }
  }
}

.certificate-form {
  margin: 30px 0;
  width: 640px;
  border-radius: 10px;
  box-shadow: 0px 3px 6px #00000029;

  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;

  .certificate-content {
    padding: 20px 105px 40px;
    font-size: 10px;
    color: #222222;
  }

  .certificate-logo-area {
    padding: 5px 30px 15px;
  }
}

.certificate-btn-area {
  width: 640px;
  float: right;
}

.certificate-item {
  position: relative;
  width: 226px;
  height: 280px;
  box-shadow: 0px 3px 6px #00000029;
  border-radius: 10px;
  margin: 20px;
  .info {
    position: relative;
    z-index: 2;
    height: 110px;
    padding: 18px 18px 15px 18px;
    color: #131F6B;
    .name {
      line-height: 18px;
      font-size: 14px;
      font-weight: bold;
    }
    i {
      font-style: initial;
      color: #D3D4D6;
    }
    span {
      font-size: 12px;
    }
  }
  .hover-block {
    z-index: 1;
    border-radius: 10px;
    display: none;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: rgba(#181818, 0.6);
  }
  &:hover {
    .hover-block {
      display: block;
    }
    .info {
      color: #fff;
      i {
        color: #fff;
      }
    }
  }
}

.multi-select {
  .multi-select-pre__multi-value {
    background: #EFF4FB;
    border-radius: 5px;
    .multi-select-pre__multi-value__label {
      color:#131F6B;
      font-weight: normal;
    }
    .multi-select-pre__multi-value__remove {
      svg {
        width: 10px;
        height: 10px;
        path: {
          fill: #131F6B;
        }
      }
      &:hover {
        background: #e1ecf9;
        color: #131F6B;
      }
    }
  }
}

.multi-select-pre__placeholder {
  font-weight: normal;
  color: #dddddd !important;
}

.overflow-x-scroll {
  overflow-x: scroll;
}

.pr-65 {
  padding-right: 65px !important;
}

.w-48 {
  width: 48%;
}

.form-date-range {
  transition: none;
  width: 100%;
  margin-bottom: 3px;
  svg {
    display: none;
  }
  .calendar-header {
    @extend .flex-row-reverse;
    background: #fff;
    border: none;
    color: #000 !important;
    font-weight: 400;
    &.no-value {
      span {
        color: #ddd !important;
      }
    }
  }
  .calendar-body {
    transition: none;
    z-index: 1000;
    border: none;
  }
  &.is-opened {
    .calendar-body {
      transition: none;
      max-width: 295px;
      border: none;
    }
  }
}

.lh-10 {
  line-height: 10px;
}

.content-scroll-max {
  max-height: 400px;
  overflow: auto;
}

.z-index-100 {
  z-index: 100;
}

.case-row-on-event {
  .case-item-title {
    width: 100%;
    margin-bottom: 8px !important;
  }
  > div {
    padding-right: 10px;
  }
  p {
    font-weight: 400;
  }
}


.react-time-picker {
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
  min-width: 166px;
  .react-time-picker__wrapper {
    border: none;
    padding-left: 10px;

    input, span, select, button {
      outline: none !important;
      border: none !important;
      box-shadow: none !important;
      font-weight: 400;
    }

    select {
      margin-left: 20px;
    }
  }
}

.my-16 {
  margin: 32px 0px;
}

.device-pair-modal {
  .modal-box {
    width: 768px;
  }
}

.max-200-scroll {
  max-height: 200px;
  overflow: auto;
}

.max-500-scroll {
  max-height: 500px;
  overflow: auto;
}

.event-cme-list {
  .cme-block {
    background: #fff;
    width: 330px;
    border-radius: 10px;
    overflow: hidden;
    -webkit-box-shadow: 0px 0px 23px 0px #00000029;
    box-shadow: 0px 0px 23px 0px #00000029;
    .info-area {
      padding: 20px 10px 16px 20px;
    }
    .title {
      font-size: 24px;
      font-weight: 500;
      color: #222222;
      margin-bottom: 5px;
      line-height: 24px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .info {
      font-size: 14px;
      color: #3D3D3D;
      margin-top: 12px;
      max-height: 44px;
      overflow: hidden;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    a {
      font-size: 14px;
      color: #008DA8;
      font-weight: bold;
    }
    .img-area {
      position: relative;
      .icon-area {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 10;
      }
    }
  }
}

.date-block {
  background: #EFF4FB;
  padding: 5px 24px;
  margin-bottom: 20px;
  .date-title {
    font-weight: 500;
    font-size: 24px;
    span {
      font-size: 48px;
      margin-right: 8px;
    }
  }

  .program-block {
    border-bottom: 2px solid rgba(#242836, 0.1);
    padding-bottom: 20px;
    margin-bottom: 20px;
    .time {
      color: #242836;
      font-size: 16px;
    }
    .desc {
      margin: 15px 0;
    }
    &:nth-last-child(1) {
      border-bottom: none;
      margin-bottom: 0;
    }
  }
}

.event-statistics {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(#FFFFFF, 0.5);
  z-index: 1000;

  .stats-block {
    color: #222222;
    padding: 18px 16px;
    width: 352px;
    box-shadow: 0px 0px 7px #00000029;
    position: absolute;
    right: 0;
    top: 75px;
    bottom: 0;
    background: #fff;
    overflow: auto;
  }
}

.event-tabs {
  border-bottom: 1px solid #EFF4FB;
  padding-bottom: 24px;
  .event-tab {
    padding: 12px;
    color: #222222;
    width: 100%;
    h4 {
      font-size: 18px;
      line-height: 18px;
      margin-top: 9px;
      margin-bottom: 0;
    }
    p {
      font-size: 12px;
      line-height: 18px;
    }
    &.tab-orange {
      background: rgba(#FFB100, 0.3);
    }
    &.tab-blue {
      background: rgba(#477EFF, 0.3);
    }
    &.tab-red {
      background: rgba(#EB4D4D, 0.3);
    }
    &.tab-green {
      background: rgba(#349A4C, 0.3);
    }
  }
}

.event-visitors {
  border-bottom: 1px solid #EFF4FB;
  padding: 24px 0;
}

.event-location {
  padding: 24px 0;
}

button.btn {
  transition: none;
}

.no-scroll-bar {
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

.r-0 {
  right: 0;
}

.flex-1 {
  flex: 1 1;
}

.__react_component_tooltip {
  &.show {
    opacity: 1 !important;
  }
  .multi-line {
    text-align: left !important;
  }
}

.error-message-alert {
  .react-confirm-alert {
    .react-confirm-alert-body {
      min-width: 400px;
      border-radius: 5px !important;
      padding: 16px 19px 16px 52px !important;
      font-size: 13px;
      white-space: pre-line;
      h1 {
        position: relative;
        font-weight: 600;
        font-size: 14px !important;
        margin-bottom: 14px;
        &:before {
          content: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24'%3E%3Cpath id='alert-circle' d='M60,48A12,12,0,1,0,72,60,12.014,12.014,0,0,0,60,48Zm0,18.456A1.154,1.154,0,1,1,61.154,65.3,1.154,1.154,0,0,1,60,66.456Zm1.253-11.6-.331,7.038a.923.923,0,0,1-1.846,0l-.331-7.035v0a1.254,1.254,0,1,1,2.506,0Z' transform='translate(-48 -48)' fill='%23e88989'/%3E%3C/svg%3E%0A");
          position: absolute;
          left: -35px;
          top: 3px;
        }
      }
    }
  }
}

.btn.no-shadow {
  &:focus {
    box-shadow: none;
    outline: none;
  }
}

.action-buttons {
  .action-item {
    min-width: 32px;
    height: 32px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: 5px;
    margin-right: 8px;
    text-align: center;
    line-height: 32px;
    color: #131f6b;
    padding: 0 3px;
    font-size: 12px;
    @extend .d-flex;
    @extend .align-items-center;
    @extend .justify-content-center;
    &.opened_popup {
      background-color: #131F6B;
    }
    &:nth-last-child(1) {
      margin-right: 0;
    }
    &:hover {
      text-decoration: none;
      border: 1px solid #131F6B;
      &.remove {
        border: 1px solid #E88989;
      }
      &.dots {
        background-color: #131F6B;
        svg {
          path {
            fill: #fff;
          }
        }
      }
    }
    &.fill-blue {
      background: #131F6B;
      svg {
        width: 16px;
        path {
          fill: #fff;
        }
      }
    }
  }
}

.text-black {
  color: #222222;
}

.share-modal {
  .user-selection {
    & > div {
      background: #EFF4FB 0% 0% no-repeat padding-box;
      border-radius: 5px;
      padding: 9px 16px;

      select {
        height: 38px;
        font-size: 14px;
        &:active, &:focus {
          box-shadow: none !important;
          outline: none;
        }
      }
    }
  }

  .share-modal-left-head {
    display: flex;
    column-gap: 16px;
    label {
      margin-left: 0;
      margin-bottom: 0 !important;
    }
  }

  .user-select {
    z-index: 1000;
    div {
      z-index: 999;
    }
  }

  .selected-users {
    min-height: 135px;
    max-height: 270px;
    overflow-y: auto;

    select {
      &:active, &:focus {
        box-shadow: none !important;
        outline: none;
      }
    }
  }

  .btn-copy-link {
    display: flex;
    column-gap: 7px;
  }

  .btn-save {
    justify-self: flex-end
  }
}

.mw-100px {
  max-width: 100px;
}

.h-90 {
  height: 90%;
}

.h-200 {
  height: 200px;
}

.mw-150px {
  max-width: 150px;
}

.password-input {
  border-bottom: 2px solid #d3d4d7;

  .pass-icon {
    border-right: 2px solid #d3d4d7;
  }
  .input-group {
    border: none !important;
  }
}

.model-info {
  background: #FFFFFF 0% 0% no-repeat padding-box;
  border: 1px solid #131F6B;
  border-radius: 2px;
  padding: 16px;
  width: 240px;
  margin: 0 auto;
  margin-top: 32px;
  > div {
    font-size: 13px;
    border-bottom: 1px solid rgba(#707070, 0.2);
    padding: 12px 0;
    &:nth-child(1) {
      padding-top: 0;
    }
    &:nth-last-child(1) {
      padding-bottom: 0;
      border-bottom: 0;
    }
  }
}

.mt-100 {
  margin-top: 100px;
}

.step-check-icon {
  position: absolute;
  width: 15px;
  bottom: 6px;
}

.custom-toggle {
  zoom: 0.7;
  .react-switch-bg {
    // background: rgb(19 31 107) !important;
  }
}

.less_padding {
  padding: 2px;
}

.full_width {
  width: 100%;
}

.icon-check-circle {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  background: #569A58;
  line-height: 17px;
  text-align: center;
}

.white-bg-children {
  * {
    background-color: #fff !important;
  }
}

.action-buttons-container {
  min-width: 130px;

  .three-dots {
    font-size: 30px;
    margin-bottom: 16px;
  }
}

.thick_tb_row {
  min-height: 86px !important;
  padding-left: 15px !important;

  div {
    height: 50px;
    display: flex;
    align-items: center;
    font-weight: normal !important;
  }
}

.thick_row_tb_head {
  padding-left: 15px !important;
}

.max-w-250 {
  max-width: 250px;
}

.not_display {
  display: none !important;
}
.two_items_in_form_row {
  column-gap: 12px;
}

.text_no_wrap {
  white-space: nowrap;
}

.indicator_text {
  font-size: 8px;
}

.form_gray_text{
  font-size: 14px;
  letter-spacing: 0.28px;
  color: #242836;
  opacity: 0.5;
  font-weight: normal;
}

.force_wrap {
  word-break: break-all !important;
}

.text_start {
  justify-content: flex-start !important;
}

.load_more {
  gap: 10px;
  font-size: 14px;

  p {
    margin: unset !important;
  }
}

.min_90 {
  min-width: 90px !important;
}

.min_60 {
  min-width: 61px !important;
}

.min_140 {
  min-width: 140px !important;
}

.min_132 {
  min-width: 132px !important;
}

.h_31 {
  height: 31px;
}
.no_set_width {
  width: unset !important;
}

.global_disabled {
  opacity: 0.6;
  pointer-events: none;
}

.h_31 {
  height: 31px;
}

.opacity_2 {
  opacity: 0.2 !important;
}

.custom_editor_variable {
  padding: 4px;
  display: inline-block;
  background-color: #131F6B;
  color: #fff;
  border-radius: 32px;
  border: 2px dashed #fff;
  margin-right: 2px;
}

.unset_height {
  height: unset;
  max-height: unset;
}

.first_ordered {
  order: -1;
}

.mark_passed_label {
  display: flex;
  align-items: center;
  gap: 8px;
  letter-spacing: 0px;
  color: #242836;
  font-size: 12px;
  .author_name {
    text-decoration: underline;
    color: #242836;
  }
}

.regular_modal_cancel {
  color: #222222 !important;
}

.gap_8 {
  gap: 8px;
}

.disabled_bg_gray {
  background-color: #F4F7FC;
}

.disabled_bg_opc_4 {
  opacity: 0.4;
}

.mw_60 {
  max-width: 60px;
}

.mw_70 {
  max-width: 70px;
}

.mw_80 {
  max-width: 80px;
}

.fsize_18 {
  font-size: 18px;
}

.to_learner_block {
  position: absolute;
  z-index: 1000;
  top: 20px;
  right: 80px;
  > .btn {
    font-size: 12px;
  }
}

.no-access {
  h2 {
    font-size: 24px;
    color: #E88989;
    margin-bottom: 16px;
    svg {
      margin-right: 12px;
    }
  }
}

.left_m_4 {
  margin-left: 4px;
}

.action_btn_filled {
  background-color: #131F6B !important;
}

.focus_no_shadowed {
  &:focus {
    box-shadow: unset;
  }
}

.hidden_file_inp {
  visibility: hidden;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 99;
}

.ml_130 {
  margin-left: 130px;
}

.not_visible {
  visibility: hidden;
}

.color_unset {
  color: unset;
}

.no_hover_styles {
  &:hover {
    text-decoration: none;
    color: inherit;
  }
}

.mw-138 {
  min-width: 138px;
}

.new-wizard-footer {
  box-shadow: 0px 0px 14px 0px #C7D2FF;
  padding: 16px 24px;
}

.fixed-hidden-input {
  opacity: 0;
  position: fixed;
}

.highlighted-action-text {
  font-weight: 800;
}

.selectable-filters-block {
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  overflow: auto;

  .item {
    font-size: 10px;
    font-weight: 500;
    border-radius: 5px;
    padding: 10px 24px;
    color: #131F6B;
    background-color: #EFF4FB;
    cursor: pointer;

    &.active {
      order: -1;
      color: #ffffff;
      background-color: #131F6B;
    }
    &.first {
      order: -2;
    }
  }
}

.upload-multi-images {
  row-gap: 16px;
  .images-block {
    display: flex;
    flex-wrap: wrap;
    gap: 24px;
    .img-block {
      position: relative;
      width: 100px;
      height: 100px;
      .remove-icon {
        position: absolute;
        right: -12px;
        top: -12px;
        cursor: pointer;
      }
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
  }
}

.vertical-custom_scrollbar_block {
  position: relative;
  &::-webkit-scrollbar {
    position: absolute;
    height: 10px;
    margin-top: 16px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    height: 10px;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #131F6B;
    border-radius: 5px;
    height: 10px;
  }

  &.thin-scrollbar {
    &::-webkit-scrollbar {
      width: 4px;
      margin-left: 16px;
    }

    &::-webkit-scrollbar-track {
      width: 4px;
    }

    &::-webkit-scrollbar-thumb {
      width: 4px;
    }
  }
}

.z-0 {
  z-index: 0;
}

.regular-form-block {
  background-color: #EFF4FB;
  border-radius: 5px;
  border: none;
}

.col-gap-4 {
  column-gap: 4px;
}

.img-cover {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.max-row-5 {
  display: block;
  overflow: hidden;
  line-height: 1.4em;
  max-height: 7em;
  text-overflow: ellipsis;
}

.max-row-4 {
  display: block;
  overflow: hidden;
  line-height: 1em;
  max-height: 4em;
  text-overflow: ellipsis;
}

.text-align-left {
  text-align: left !important;
}

.bold-text-blue-md {
  font-size: 14px;
  font-weight: 800;
  color: $text-main-color;
}

.break-txt-anywhere {
  line-break: anywhere !important;
}

.shrink-0 {
  flex-shrink: 0;
}

.no-right-border {
  border-right: none !important;
}

.h-34 {
  height: 34px;
}

.inner-btn-link {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  color: inherit;
  &:hover {
    color: inherit;
  }
}

.left-no-radius {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.right-no-radius {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}

.color-dark {
  color: rgba(24, 24, 24, 1) !important;
}

p.info-row-item {
  margin-right: 15px;
  font-size: 13px;
  font-weight: 400;
  color: rgba(36, 40, 54, 1);
}

.mx--2 {
  margin: 0px 1.5px;
}
