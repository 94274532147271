.hide {
  display: none;
}

.hidden_visual {
  opacity: 0;
  position: absolute;
  &.top_right {
    top: 0;
    left: 100%;
  }
}

.page-title {
  padding: 33px 21px 20px;
  box-shadow: 0 0 5px 2px rgba(0, 0, 0, 0.1)
}

.list-default {
  margin: 0;
  padding: 0;
  list-style: none;

  li {
    border-radius: 5px;
    background: #eff4fb;
    padding: 18px;

    &:not(:last-child) {
      margin-bottom: 6px;
    }
  }
}


.plan-progress-box {
  width: 100%;
  display: block;
  height: 10px;
  border-radius: 2px;
  background: #fff;
  overflow: hidden;

  .progress-completed {
    height: 100%;
    display: block;
    background: #131f6b;
    border-radius: 2px;
  }
}

.semi-checked {
  &::after {
    content: '';
    display: block;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 8px;
    height: 1px;
    background: #131f6b;
  }
}

.outline-blue {
  border: 1px solid #131f6b;
}

.outline-transparent {
  border: 1px solid transparent;
}

.icons-list path {
  fill: #131f6b
}

.status-explanation-list {
  list-style: none;

  li {
    position: relative;
    &::after {
      content: '';
      left: -16px;
      transform: translateY(-50%);
      border-radius: 50%;
      position: absolute;
      top: 50%;
      width: 8px;
      height: 8px;
    }
    &:nth-child(1)::after {
      background: #569A58;
    }
    &:nth-child(2)::after {
      background: #699CD6;
    }
    &:nth-child(3)::after {
      background: #34609A;
    }
    &:nth-child(4)::after {
      background:rgb(17, 50, 228);
    }
    &:nth-child(5)::after {
      background:rgb(96, 119, 247);
    }
  }

  &.is-event-view {
    li {
      &:nth-child(1)::after {
        background: #FFC84C;
      }
      &:nth-child(2)::after {
        background: #78A0FE;
      }
      &::after {
        margin-top: 2px;
      }
    }
  }
}

.text-truncate-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.rounded-5 {
  border-radius: 5px;
}

.rounded-10 {
  border-radius: 10px;
}

.rounded-15 {
  border-radius: 15px;
}

.doughnut-chart {
  position: relative;
  z-index: 0;

  .center-text {
    position: absolute;
    color: #131F6B;
    font-weight: 600;
    z-index: -1;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 18px;
  }
}

.bg-semi-light {
  background-color: #E5E5E5
}

.app-tooltip {
  position: relative;

  .tooltip-content {
    width: max-content;
    max-width: 200px;
    padding: 10px 15px 8px;
    border-radius: 4px;
    font-size: 12px;
    line-height: 16px;
    text-align: left;
    background: #eff4fb;
    color: #131f6b;
    font-weight: normal;
    position: absolute;
    z-index: 999999;
    transition: 100ms;
    cursor: default;

    &::after {
      content: '';
      width: 12px;
      height: 12px;
      position: absolute;
      background: inherit;
      z-index: -1;
    }
  }

  &__dark {
    .tooltip-content {
      background: #131f6b;
      color: #eff4fb
    }
  }

  &__left,
  &__right {
    .tooltip-content {
      top: 50%;
      transform: translateY(-50%) scale(0);
      &::after {
        top: 50%;
        transform: translateY(-50%) rotate(45deg);
      }
    }
  }
  &__top,
  &__bottom {
    .tooltip-content {
      left: 50%;
      transform: translateX(-50%) scale(0);
      &::after {
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
      }
    }
  }

  &__left .tooltip-content {
    transform-origin: top right;
    right: 100%;
    margin-right: 10px;
    &::after {
      right: -5px;
    }
  }
  &__right .tooltip-content {
    transform-origin: top left;
    left: 100%;
    margin-left: 10px;
    &::after {
      left: -5px;
    }
  }
  &__top .tooltip-content {
    transform-origin: bottom center;
    bottom: 100%;
    margin-bottom: 10px;
    &::after {
      bottom: -5px;
    }
  }
  &__bottom .tooltip-content {
    transform-origin: top center;
    top: 100%;
    margin-top: 10px;
    &::after {
      top: -5px;
    }
  }

  &__pinned-top,
  &__pinned-up {
    .tooltip-content {
      transform-origin: left 10px;
      transform: scale(0) translateY(0);
      &::after {
        transform: rotate(45deg);
      }
    }
  }

  &__pinned-top .tooltip-content {
    top: 0;
    &::after {
      top: 5px;
    }
  }
  &__pinned-up .tooltip-content {
    top: -5px;
    &::after {
      top: 12px;
    }
  }

  &__left:hover, &__left.hover,
  &__right:hover, &__right.hover {
    .tooltip-content {
      transform: scale(1) translateY(-50%);
    }
  }

  &__top:hover, &__top.hover,
  &__bottom:hover, &__bottom.hover {
    .tooltip-content {
      transform: scale(1) translateX(-50%);
    }
  }

  &__pinned-top:hover, &__pinned-top.hover,
  &__pinned-up:hover, &__pinned-up.hover {
    .tooltip-content {
      transform: scale(1) translateY(0);
    }
  }

  &__pinned-left {
    .tooltip-content {
      transform-origin: top left;
      transform: scale(0) translateX(0);
      left: 0;
    }
    &:hover, &.hover {
      .tooltip-content {
        transform: scale(1) translateX(0);
        left: 0;
        &::after {
          transform: rotate(45deg) translate(0);
          left: 8px;
        }
      }
    }
  }
}

.btn-text-remove {
  font-size: 14px;
  color: #E88989;
  svg path {
    fill: #E88989;
  }
  &:hover {
    color: #E88989;
  }
}
