.btn-blue {
  color: $color-blue !important;
  background-color: $color-blue-light !important;
  &.text-orange {
    color: #E88989 !important;
  }
  &.btn-restyle {
    &:disabled {
      background-color: $bg-disabled-main !important;
      color: #EFF4FB !important;
    }
  }
}

.btn-outline-blue {
  color: $color-blue !important;
  border-color: $color-blue !important;
  &:disabled {
    border-color: #B1B1B1 !important;
    color: #B1B1B1 !important;
    opacity: 1 !important;
  }
}

.btn-red {
  color: #fff;
  background-color: #E88989;
  &:hover {
    color: #fff;
  }
}

.btn-blue-dark {
  background: #131f6b;
  color: #fff;

  &:hover {
    color: #fff;
  }
}

.btn-outlined-white {
  border: 2px solid #fff;
}

.btn-outline-blue {
  border: 1px solid #131F6B;
  font-size: 12px;
  color: #131F6B;
  font-weight: 500;
  transition: .4s;
  &.with_hover_style {
    &:hover {
      color: #FFFFFF !important;
      background-color: #131F6B;
    }
  }
}

.btn-width-300 {
  min-width: 300px
}


.btn-white {
  color: $color-blue !important;
  background-color: #fff;
}

.btn-primary {
  background-color: #131f6b;
  color: #fff;
  border: none;

  &:hover, &:active {
    background-color: #0d154c !important;
    color: #fff !important;
  }
  &:disabled {
    background-color: #131f6b;
  }

  &:focus, &.focus {
    background-color: #0d154c !important;
    box-shadow: none;
  }
  &.btn-restyle {
    &:disabled {
      background-color: $bg-disabled-main !important;
      color: #EFF4FB !important;
    }
  }
}

.btn_transparent {
  width: fit-content;
  height: fit-content;
  padding: unset;
  &:focus {
    box-shadow: none;
  }
}

.btn-outline-primary {
  border-color: #131f6b;
  color: #131f6b;

  &:hover, &:active {
    background-color: #131f6b !important;
  }
}

.btn-no-outline {
  border: none;
}

.btn-rounded {
  border-radius: 30px;
}

.btn-truncate {
  @extend .text-truncate
}

.btn {
  &:hover {
    cursor: pointer;
  }

  &.center-aligned {
    display: flex;
    align-items: center;
  }

  &-md {
    height: 35px;
    border-radius: 5px;
    font-size: 12px;
    min-width: 100px;
    font-weight: 500;
  }
  &-md2 {
    height: 30px;
    border-radius: 5px;
    font-size: 13px;
    min-width: 75px;
    font-weight: 400;
  }
  &-border-2 {
    border-width: 2px;
  }
}

.btn-no-shadow {
  &:focus {
    box-shadow: none;
  }
}

.btn-small-radius {
  border-radius: 5px;
}

.btn-delete-1 {
  letter-spacing: 0;
  background: #FFFFFF 0% 0% no-repeat padding-box;
  color: #E88989;
  border: none;
}

.btn-disabled {
  background: #B1B1B1 0% 0% no-repeat padding-box;
  color: #FFFFFF;
  border: none;

  pointer-events: none;
}

.btn-left-icon-copy-link {
  display: flex;
  justify-content: center;
  column-gap: 8px;
  align-items: center;
}

.btn-purple-filled {
  background-color: #AF3DB2;
  border-radius: 8px;
  color: #FFFFFF;
  font-size: 14px;
  font-weight: 700;
}