.postfix-input-container {
  display: flex; 
  position: relative;
  overflow: auto;
  background-color: #fff;
  font: 14px Roboto; 
  font-weight: 400;
  padding-bottom: 0 !important;

  .postfix-input {
    font: inherit;
  }
  .postfix {
    position: absolute;
    top: 8px;
    color: #B1B1B1;
    font: inherit
  }
}