.input-wrapper {
  position: relative;
  .regular_input{
    width: 100%;
    height: 38px;
    border-radius: 5px;
    background: #FFFFFF;
    border: none;
    padding-left: 16px;
    ::placeholder {
      color: #C4C4C4;
    }
    &.small {
      height: 20px;
      padding-left: 8px;
    }
  }
  .input-icon {
    position: absolute;
    top: calc(50% - 8px);
    right: 10px;
    pointer-events: none;
  }
}

