// MeasurementDialog.scss
.measurement-dialog-container {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
  height: auto;
  width: 424px;
  padding: 20px;
  position: fixed;
  top: 60%;
  left: 64%;
  transform: translate(-50%, -50%);
  background: #212121;
  z-index: 1000;
  border-radius: 10px;
  color: #ffffff;
  padding: 16px;

  .viewer-input-group {
      height: 50px;
      position: relative;
      background-color: #000;
      display: flex;
      align-items: center;
      column-gap: 16px;
      border-radius: 10px;
      padding: 9px 9px 9px 14px;

      .probability-input {
          width: 100%;
          padding: 10px;
          height: 32px;
          border-radius: 4px;
          border: none;
          background: #333;
          color: white;
          font-size: 16px;
          padding: 5px 5px 5px 14px;

          &::placeholder {
            color: $dicom-viewer-color;
            font-size: 14px;
            font-weight: 400;
          }

          &:focus {
              outline: none;
          }
      }

      .input-addon {
          position: absolute;
          right: 15px;
          color: $dicom-viewer-color;
          font-size: 22px;
      }
  }

  .designator-block {
    display: flex;
    flex-direction: column;
    row-gap: 8px;

    .block-title {
      color: $dicom-viewer-color;
      font-size: 14px;
      font-weight: 400;
    }
  }
  .designator-items-block {
      display: flex;
      flex-wrap: wrap;
      gap: 8px;
      background: #313131;
      border-radius: 10px;
      padding: 8px;

      .designator-button {
          position: relative;
          height: 40px;
          width: 40px;
          background: #000;
          border: none;
          color: $dicom-viewer-color;
          font-size: 16px;
          font-weight: 400;
          border-radius: 4px;
          cursor: pointer;

          .shape-icon {
            width: 8px;
            height: 8px;
            position: absolute;
            bottom: 3px;
            left: calc(50% - 4px);
          }

          &:focus {
              outline: none;
          }

          &.selected {
              background: #C8C8C8;
              color: #000;
          }
      }
  }

  .measurement-dialog-actions {
      display: flex;
      justify-content: flex-end;
      column-gap: 16px;

      button {
          height: 36px;
          width: 98px;
          border-radius: 4px;
          border: none;
          cursor: pointer;
          font-weight: bold;
          background-color: #000;
          color: $dicom-viewer-color;
      }
  }
}
