.modal_parts_separator_line {
  width: 100%;
  height: 2px;
  background: #242836 0% 0% no-repeat padding-box;
  opacity: 0.2;
  margin: 20px 0;
}

.id_block_container {
  display: flex;
  column-gap: 8px;
}

.device_block {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
  width: 100%;
}

.name_block {
  display: flex;
  column-gap: 20px;
  width: 100%;
  height: 36px;
  padding: 7px 12px 9px 12px;
  background: #EFF4FB 0% 0% no-repeat padding-box;
  border-radius: 5px;
}

.id_block {
  position: relative;
  width: 100%;
  height: 119px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #EFF4FB 0% 0% no-repeat padding-box;
  border-radius: 5px;
  margin-bottom: 16px;

  .title {
    position: absolute;
    top: 4px;
    left: 8px;
  }
}

.switch_type_container {
  display: flex;
  width: 100%;
  padding-left: 10px;
  align-items: center;
  margin-bottom: 16px;

  &.disabled:first-child {
    opacity: 0.7;
  }

  .MuiFormControlLabel-root {
    margin-bottom: 0;
  }
}

.m-footer {
  display: flex;
  justify-content: flex-end;

  .footer_btns_block {
    display: flex;
    column-gap:16px;
  }
}

.device_id {
  letter-spacing: 13.86px;
  color: #242836;
  font-size: 42px;
  font-weight: bold;
}