.export_tb_btn {
  position: relative;
  height: 35px;
  background: #EFF4FB 0% 0% no-repeat padding-box;
  border-radius: 5px;
  color: #131f6b;
  font-size: 12px;
  letter-spacing: 0.24px;
  display: flex;
  align-items: center;
  column-gap: 8px;
  justify-content: center;
  box-shadow: unset !important;

  &.active {
    border: 1px solid #131f6b;
    background: #131F6B 0% 0% no-repeat padding-box;
    color: #FFFFFF;
  }

  .inner_icon {
    align-self: center;
  }

  .drop_list_block {
    position: absolute;
    top: calc(100% + 5px);
    right: -2px;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border: 1px solid #E3E9F2;
    border-radius: 5px;
    padding: 13px 24px 13px 12px;
    z-index: 9;
    width: max-content;
    height: max-content;
    display: flex;
    flex-direction: column;
    gap: 12px;
    color: #242836;
    font-size: 14px;

    .drop_list_item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      column-gap: 10px;
      width: 100%;
      height: 100%;
      transition: .4s;
      padding: 6px 0 6px 6px;

      svg {
        align-self: flex-end;
        width: 34px;
        height: auto;
      }

      &:hover {
        background-color: #EFF4FB;
      }
    }
  }
}