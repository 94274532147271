.right_sidebar_container {
  max-width: 310px;
  padding-right: 40px;
  padding-top: 32px;
  height: fit-content;

  .side_regular_block {
    width: 100%;
    padding: 16px 12px;
    background-color: #EFF4FB;
    border-radius: 3px;
    color: #222222;

    &.quiz_prop_block {
      gap: 16px;
      margin-bottom: 12px;
      .list_container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 6px;
        .prop_list_item {
          display: flex;
          gap: 8px;

          .prop_value {
            margin-left: auto;
          }
        }
      }
    }

    &.search_block {
      margin-top: 24px;
      display: flex;
      flex-direction: column;
      gap: 6px;

      .q_groups_block {
        display: flex;
        flex-wrap: wrap;
        gap: 6px;
      }
    }

    .side_block_title {
      font-size: 16px;
      font-weight: 600;
    }
  }
}