.virtual-copy-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-grow: 1;
  .copy-text {
    font-size: 14px;
    font-weight: 500;
    color: $primary-color;
  }
  .actions-block {
    display: flex;
    column-gap: 1px;
  }
}