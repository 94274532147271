.sub_head {
  display: flex;
  width: 100%;
  height: 50px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 32px;
  padding: unset;

  .name_container {
    background: #EFF4FB 0% 0% no-repeat padding-box;
    height: 100%;
    border-radius: 4px;
    display: flex;
    align-items: center;
    padding: 16px 12px;
    gap: 100px;
    label {
      margin-left: 12px !important;
      font-weight: bold;
    }
  }
}

.sub_title {
  color: #131F6B;
}