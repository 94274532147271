.quiz_page_container {
  padding-top: 75px;
  font-family: Roboto;
  color: #131f6b;
  .btn-primary {
    min-width: 80px;
    height: 34px;
  }
  .btn {
    font-size: 12px;
  }
  .user-creation-form {
    li {
      margin-bottom: unset;
    }
  }
}


.quiz_content_block {
  display: flex;
  flex-direction: column;
  row-gap: 14px;
  padding: 16px;
  span, label {
    font-weight: 400;
  }
  .list-default {
    margin-bottom: unset;
  }
  .step_block{
    padding: 16px 0 24px 16px;
    border: 0.5px dashed #AFAFAF;
    min-height: 200px;
    max-width: 100%;
    border-radius: 4px;
    margin-top: 18px;

    .empty_step_items_block {
      gap: 26px;
    }
  }
  .right_block {
    display: flex;
    align-items: center;
    column-gap: 16px;
    min-height: 32px;
  }
  .title {
    font-size: 24px;
    font-weight: 700;
    letter-spacing: 0em;
    margin-left: 16px;
  }
  .block {
    &.regular_block {
      padding-left: 2px;
      &.disabled {
        li {
          background: #EBEBEB;
          pointer-events: none;
          div {
            color: #C5C5C5;
          }
        }
      }
      ul {
        display: flex;
        flex-direction: column;
        row-gap: 8px;
      }
    }
    .title{
      font-size: 20px;
      font-weight: 500;
    }
  }
}

.steps_part_block {
  position: relative;
  display: flex;
  column-gap: 16px;
  height: 143px;
  align-items: center;

  .absolute_overlay {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }

  .left_sticky_div {
    position: sticky;
    left: 0;
    z-index: 99;
  }

  .gradient {
    position: absolute;
    width: 64px;
    height: 130px;
    top: 0;
    z-index: 1;
      &.start {
        left: 80px;
        background: linear-gradient(90deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 122.92%);
      }
      &.end {
        right: 0;
        background: linear-gradient(270deg, #FFFFFF 0%, rgba(255, 255, 255, 0) 122.92%);
      }
  }

  .draggable_block {
    // position: relative;
    display: flex; 
    column-gap: 16px; 
    width: 100%; 
    height: 100%;
    align-items: center;
    overflow-y: hidden; // Hide vertical scrolling
    overflow-x: auto;
  }
}

.add_step_btn {
  width: 80px;
  height: 80px;
  background-color: #EFF3FC;
  border-radius: 5px;
  border: 1px solid #131f6b;
  color: #131f6b;
}

.custom_scrollbar_block {
  position: relative;
  &::-webkit-scrollbar {
    position: absolute;
    height: 10px;
    margin-top: 16px;
  }

  &::-webkit-scrollbar-track {
    background: #f1f1f1;
    height: 10px;
    border-radius: 5px;
  }

  &::-webkit-scrollbar-thumb {
    background: #131F6B;
    border-radius: 5px;
    height: 10px;
  }

  &.thin-scrollbar {
    &::-webkit-scrollbar {
      height: 2px;
      margin-left: 16px;
    }
  
    &::-webkit-scrollbar-track {
      height: 2px;
    }
  
    &::-webkit-scrollbar-thumb {
      height: 2px;
    }
  }
}

.quiz_view_content_block {
  display: flex;
  padding: 32px;
  column-gap: 44px;

  .left_content {
    width: calc(100% - 350px);
    display: flex;
    flex-direction: column;
    flex: 1;
    gap: 32px;
  }

  .question-container {
    width: 100%;
    padding: 12px 0;
  
    &.result-container {
      width: 100%;
    }
  
    .question-block {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      border: 2px solid #8089B3;
      border-radius: 8px;
      padding-bottom: 24px;
      padding-right: 2px;
  
      &.success {
        .body {
          display: flex;
          flex-direction: column;
          row-gap: 44px;
          align-items: center;
          padding: 42px 20px 0 20px;
  
          .details-block {
            display: flex;
            column-gap: 34px;
            font-size: 14px;
  
            .detail-item {
              font-weight: 400;
              color: #777777;
  
              .value {
                color: #131F6B;
                font-weight: 700;
  
                &.danger {
                  color: #FB7575;
                }
              }
            }
          }
          .congrats-block {
            display: flex;
            flex-direction: column;
            align-items: center;
            color: #222222;
            font-size: 24px;
            font-weight: 400;
  
            .congrats {
              font-size: 32px;
              color: #2DA41A;
            }
            .oops {
              font-size: 36px;
              color: #FA5252;
            }
          }
        }
      }
  
      p {
        margin-bottom: unset;
      }
  
      .question-head {
        display: flex;
        gap: 22px;
        width: 100%;
        min-height: 86px;
        background-color: #EFF4FB;
        color: #2B2D42;
        padding: 10px 32px 16px 38px;
        font-size: 16px;
        font-weight: 500;
  
        &.success {
          justify-content: center;
          align-items: center;
          .title {
            font-size: 46px;
            font-weight: 500;
            color: #2B2D42;
            margin-bottom: 0;
          }
        }
  
        .q-number-in-all {
          font-size: 46px;
          margin-left: auto;
          line-height: normal;
          
          .success {
            color: #2DA41A;
          };
          .failed {
            color: #FA5252;
          };
          .skipped {
            color: #ffffff;
          }
        }
        .q-description {
          margin: 10px 0;
        }
        .q-number {
          margin-top: 11px;
        }
      }
      .question-body {
        position: relative;
        width: 100%;
        height: 100%;
        padding-left: 40px;
        .expired-question-indication {
          position: absolute;
          top: 0;
          right: 40px;
          height: 34px;
          width: 96px;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: #FA5252;
          color: #ffffff;
          font-size: 20px;
          font-weight: 500;
          border-radius: 0 0 100px 100px;
        }
        .descriptions-block {
          display: flex;
          flex-direction: column;
          gap: 8px;
          padding-left: 10px;
          font-size: 12px;
        
          .drag-descriprion {
            font-weight: 400;
            color: #777777;
          }
          .img-description {
            height: 20px;
            font-weight: 700;
            color: #131F6B;
          }
        }
      }
      .answer-btns-block {
        width: 100%;
        display: flex;
        justify-content: center;
        gap: 16px;
        margin-top: auto;
      }
      .btn-quiz-main {
        font-size: 16px;
        font-weight: 700;
        color: #131F6B;
        padding: 8px 24px;
  
        &.what-wrong {
          max-width: 202px;
          align-self: center;
          margin-top: 24px !important;
        }
      }
      .btn-quiz-outlined-white {
        font-size: 16px;
        font-weight: 700;
        color: #131F6B;
        border: 1px solid #131F6B;
        background-color: #fff;
        padding: 8px 24px;
      }
    }

    .answers-content {
      display: flex;
      flex-direction: column;
      row-gap: 24px;
      padding: 32px 72px 10px 72px;
    
      &.image-type {
        padding: 8px 22px 0 60px;
        row-gap: 12px;
      }
    
      .type-block {
        font-size: 14px;
        font-weight: 700;
        color: #777777;
    
        .color-primary {
          color: #131F6B;
        }
      }
      .answers-block {
        display: flex;
        flex-direction: column;
        row-gap: 8px;
    
        .answer-row {
          display: flex;
          column-gap: 10px;
    
          .answer-checkbox {
            position: absolute;
            opacity: 0;
    
          }
          .custom-checkbox {
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 24px;
            height: 24px;
            border-radius: 50%;
            background-color: #EFF3FC;
    
            .answer-check {
              position: absolute;
              top: -5px;
              right: -2px;
            }
          }
    
          .answer-radio {
            position: absolute;
            opacity: 0;
            &:checked + .answer-text .custom-radio {
              border: 2px solid #131F6B;
            }
            &:checked + .answer-text .custom-radio::before {
              background: #131F6B;
            }
          }
    
          .answer-text {
            position: relative;
            padding-left: 32px;
            font-size: 16px;
            font-weight: 400;
            color: #222222;
            margin-bottom: 0;
    
            .custom-radio {
              position: absolute;
              left: 0;
              top: 50%;
              transform: translateY(-50%);
              width: 24px;
              height: 24px;
              border-radius: 50%;
              background-color: #EFF3FC;
        
              &::before {
                content: "";
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 14px;
                height: 14px;
                border-radius: 50%;
                background: transparent;
              }
            }
          }
        }
      }
    }

    .quiz_view_img_column_container {
      width: 196px;
      height: 280px;
      border-radius: 6px;
      overflow: hidden;
      flex-shrink: 0;
    
      &.wide {
        width: 284px;
      }
    
      .head_block {
        width: 100%;
        height: 35px;
        display: flex;
        align-items: center;
        padding-left: 16px;
        background-color: #131F6B;
        color: #fff;
      }
      .body_block {
        width: 100%;
        height: calc(100% - 35px);
        background-color: #fff;
        padding: 14px;
        border: 1px solid #EBEBEB;
        .scrollable_block {
          width: 100%;
          max-height: 100%;
          display: flex;
          flex-wrap: wrap;
          column-gap: 6px;
          row-gap: 8px;
          overflow-y: auto;
          .img_item {
            position: relative;
            width: 80px;
            height: 56px;
            border-radius: 6px;
            border: 1px solid #EBEBEB;
            overflow: hidden;
            &.selected {
              border-color: #131F6B;
            }
            cursor: pointer;
              .overlay {
                position: absolute;
                width: 100%;
                height: 100%;
                &.success {
                  background: rgba(45, 164, 26, 0.6);
                }
                &.danger {
                  background: rgba(235, 77, 77, 0.6);
                }
              }
            img {
              width: 100%;
              height: 100%;
              object-fit: contain;
            }
          }
        }
      }
    }
    .images-columns-container {
      display: flex;
      gap: 8px;
      width: 100%;
      overflow-x: auto;
      padding-bottom: 20px;
      padding-top: 10px;
    } 
  }

  .empty_quiz_block {
    row-gap: 32px;
  }

  .right_content {
    width: 310px;
    min-width: 310px;

    .side_regular_block {
      width: 100%;
      padding: 16px 12px;
      background-color: #EFF4FB;
      border-radius: 3px;
      color: #222222;

      &.quiz_prop_block {
        gap: 16px;
        margin-bottom: 12px;
        .list_container {
          display: flex;
          flex-direction: column;
          justify-content: center;
          gap: 6px;
          .prop_list_item {
            display: flex;
            gap: 8px;

            .prop_value {
              margin-left: auto;
            }
          }
        }
      }

      &.search_block {
        margin-top: 24px;
        display: flex;
        flex-direction: column;
        gap: 6px;

        .q_groups_block {
          display: flex;
          flex-wrap: wrap;
          gap: 6px;
        }
      }

      .side_block_title {
        font-size: 16px;
        font-weight: 600;
      }
    }
  }

  .empty_sec_block {
    width: 100%;
    min-height: 232px;
    background-color: #FAFAFA;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    row-gap: 32px;
    flex: 1;

    .block_title {
      font-size: 20px;
      font-weight: 500;
    }
  }
}

.question_type_label {
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 12px;
  color: #C4C4C4;
  background-color: #fff;
  font-size: 10px;
  font-weight: 500;
  border-radius: 5px;
  cursor: pointer;
  &:hover, 
  &.selected {
    background-color: #131F6B;
    color: #fff;
  }
}

.empty_block {
  background-color: #EFF4FB;
  height: 340px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 24px 24px 16px 24px;

  .btn-primary {
    width: 112px !important;
  }

  .items_container {
    width: 100%;

    .row_item {
      width: 100%;
      height: 85px;
      display: flex;
      align-items: center;
      padding-right: 80px;
      gap: 24px;
      border-bottom: 1px dashed #2B2D42;
      .number_idx {
        margin-bottom: 18px;
      }
    }
    .white_rows_block {
      display: flex;
      flex-direction: column;
      row-gap: 10px;
      min-width: 420px;
      
      .row {
        height: 10px;
        max-width: 420px;
        margin-right: 32px;
        background-color: #FFFFFF;
        border-radius: 5px;
      }

    }
  }
}

.question_category_label {
  padding: 9px 16px;
  border-radius: 16px;

  &.active {
    color: #131F6B;
    background-color: #fff;
  }
}

.image_cat_btns_container {
  display: flex;
  column-gap: 16px;
  margin-top: 4px;
  .btn {
    border-radius: 5px;
    padding: 10px 32px;
    height: unset;
    font-weight: 700;
  }
}
