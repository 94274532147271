nav.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  width: 230px;
  z-index: 10;
  background: #131f6b;
  background-image: url('./../../assets/side-background.png');
  background-color: #055b7e;
  background-repeat: no-repeat;
  background-size: cover;
  color: #b5bef6;
  overflow: auto;
  min-height: 100vh;

  &::-webkit-scrollbar {
    display: none;
  }

  .avatar {
    width: 76px;
    height: 76px;
    border-radius: 100%;
    padding: 6px;
    border: 1px solid #3c4aa3;

    img {
      width: 100%;
      height: 100%;
      border-radius: 100%;
      object-fit: cover;
      object-position: top;
    }
  }

  .profile-info {
    span {
      color: #b5bef6;
      font-size: 16px;
      font-weight: bold;
    }

    .log-out {
      font-size: 14px;
      color: #ffffff;
      opacity: .25;
    }
  }

  .navigation {
    > ul {
      padding: 0;
      list-style-type: none;
      > li {
        padding: 13px 20px;
        color: #b5bef6;
        font-size: 17px;
        a {
          text-decoration: none;
          color: #b5bef6;
        }
        &.active {
          background: #0a1456;
          color: #ffffff;
          > div > a {
            color: #ffffff;
          }
        }
      }
    }
  }
  .sub-menu {
    overflow: hidden;
    > li {
      > a {
        white-space: nowrap;
        font-size: 15px;
        color: #b5bef6 !important;
        text-overflow: ellipsis;
        &.active, &:hover {
          color: #fff !important;
        }
      }
    }

    &.users {
      list-style-type: square;
    }
  }
}
