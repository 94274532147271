.bottom-footer {
  height: 80px;
  width: 100%;
  background-color: #F0F3F5;
  color: #131F6B;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 130px;

  .sections-block {
    display: flex;
    gap: 20px;
    .sec-name {
      margin-bottom: unset;
      font-size: 13px;
      font-weight: 700;
    }
  }
  .icons-block {
    display: flex;
    align-items: center;
    gap: 16px;
  }
}

.purple-line {
  width: 100%;
  height: 4px;
  background-color: #7d2381;
  margin-bottom: 8px;
}