.redesigned-textarea-container {
  &.outlined-gray {
    display: flex;
    flex-direction: column;
    row-gap: 6px;
    width: 100%;

    label {
      color: $gray-700;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 0;
    }
    textarea {
      padding: 12px 14px;
      border-radius: 8px;
      border: 1px solid $gray-300;
      box-shadow: 0px 1px 2px 0px #1018280D;
      width: 100%;
      height: 128px;
      resize: none;
      &::placeholder {
        color: $gray-500;
      }
    }
  }
}