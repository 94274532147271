.question_config_content {
  padding: 32px;
}

.group_details_block {
  display: flex;
  flex-direction: column;
  gap: 16px;

  .group_title {
    font-size: 20px;
    font-weight: 500;
    color: #131F6B;
  }
  .group_description {
    font-size: 13px;
    font-weight: 400;
    color: #181818;
  }
}

.questions_container {
  display: flex;
  flex-direction: column;
  gap: 24px;
}